#user-profile
{
    position: relative;
    width: 72%;
    
}
#user-profile #profile-cover
{
    margin-top: -20px;
    height: 250px;
    background-size: cover;
    background-position: 50%;
}
#profile-nav
{
    padding: 20px 30px 20px 30px;
    height: fit-content;
    background: #ffffff;
}
#p-row1
{
    display: flex;
    direction: rtl;
}
#p-row2
{
    margin-top: 20px;
    display: flex;
    direction: rtl;
}
.p-col1
{
    display: flex;
    align-items: center;
    float: right;
    direction: rtl;
    width: 80%;
}
.p-col2
{
    display: flex;
    align-items: center;
    float: left;
    direction: ltr;
    width: 20%;

}
.p-col2 button , .p-col1 button
{
    background: transparent !important;
    border: none !important;
    color: #25252580 !important;
    padding: 0px !important;
    float: left;
    margin-right: 0px;
    padding: 1px !important;
    border-radius: 0px !important;
    direction: rtl;
    cursor: pointer;
    
}
.p-col2 button:focus
{
    box-shadow: none !important;
    
}
.p-col2 .pi
{
    font-size: 16px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    vertical-align: middle;
    
}
.p-col2 .p-splitbutton-defaultbutton
{
    display: none;
}
.p-col2 .pi:hover
{
    background: #eee;
    border-radius: 50%;
    
    
}
.p-col1 img
{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-top: 10px;
   
}
.badges img
{
    width: 80px;
height: 160px;
border-radius: 0px;
}
#suggestion-box .p-dialog-content
{
    padding: 0px 20px 30px 20px !important;
    
}


#profile-content ul
  {
    
    width: 100% !important;
    margin-bottom:0px !important;
  }
  #profile-tab li
{
    width: 20% !important;
}
#profile-tab ul a
{
    display: table;
    margin: auto;
    width: 100% !important;
}
#profile-tab ul , #profile-tab li , #profile-tab ul a
{
    background: transparent !important;
    font-size: 16px !important;
}


#profile-tab ul a:focus
{
    color: #af734f !important;
    border-color: #af734f !important;
    box-shadow: none !important;
}
#profile-tab ul a:active
{
    color: #af734f !important;
    border-color: #af734f !important;
    box-shadow: none !important;
}
#profile-tab ul a:hover
{
    color: #af734f !important;
    border-color: #af734f !important;
    box-shadow: none !important;
}
#profile-content .p-tabview .p-tabview-panels
{
    margin-top: 10px !important;
    border-radius: 5px !important;
}
#profile-content .p-tabview-nav-container
{
    background: #ffff ;
    border-radius: 5px !important;
}
#profile-content .p-tabview.p-component
{
    background: whitesmoke !important;
    border: none !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: #af734f !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    
    border-color: #af734f !important;
    
  }

 #profile-content .p-tabview .p-tabview-panels
  {
    background: transparent;
  }
#about-panel
{
    display: flex;
    background: transparent;
    height: fit-content;
}
#posts-panel
{
    background: transparent;
    display: flex;
    height: fit-content;
}
#tab1-col1
{
    display: table;
    /* margin:auto;?\ */
    width: 70%;
  
}
#about-panel #tab1-col1
{

    direction: rtl;
}

#about-panel #tab1-col1 img
{
    width:200px;
    height:200px;

}

#tab1-col2
{
    display: block;
    width: 30%;
}

#posts-panel #tab1-col2
{
  visibility: hidden;
}
#posts-panel #feed {
    padding: 0px 50px 0px 80px;
  }
  .info-box
  {
    background: #ffffff;
    border-radius: 5px;
    width: 100%;
    margin: 0px 10px 30px 20px;
    height: fit-content;
    padding: 20px;
    direction: rtl;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  }

  .info-box-header
  {
    display: flex;
  }
  .info-header-col1
  {
    display: flex;
    width: 90%;
    float: right;
  }
  .info-header-col2
  {
    display: flex;
    width: 10%;
    float: left;
  }
  .info-header-col2 .pi
  {
    font-size: 12px;
    cursor: pointer;
  }
  .info-header-col1 h6
  {
    margin: 0px;
  }
  .info-box-content
  {
    margin-top: 20px;
    text-align: right;
direction: rtl;
font-size: 16px;
color: #25252580;

  }
  .info-box-content .pi
  {
    margin-left: 10px;
  }

  #profile-cover .pi
  {
    font-size: 25px;
    background: #25252580;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    color: #ffffff;
    width: fit-content;
  }
  #profile-cover
  {
    text-align: left;
    padding: 20px;
  }

  #edit-profile-form .p-dialog-header
  {
    padding-bottom: 10px !important;
    border-bottom: 1px solid #eee !important;
  }
  #edit-profile-form_content
  {
    padding-top: 20px !important;
  }
  #edit-profile-form_content label
  {
    font-size: 16px !important;
    float: right;
    margin-bottom: 10px;
    text-align: right;
  }
  #edit-profile-form_content textarea
  {
    height: 200px;
  }
  textarea#description 
  {
    height: 200px;
  }
  #edit-profile-form_content  button:focus
  {
    box-shadow: none !important;
  }
  input.has-value::placeholder,textarea.has-value::placeholder
  {
    color: #252525 !important;
  }
  input.no-value::placeholder,textarea.no-value::placeholder
  {
    color: #25252560 !important;
  }
  .about-box
  {
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
    padding: 10px;
    margin: 0% 10% 5% 10%;
  }
  .about-box h6
  {
    margin: 0px 10px 0px 0px;
    color: #252525;
    text-align: right;
  }
  .about-box p
  {
    
    color: #25252580 !important;
    margin: 10px 20px 10px 0px !important;
    text-align: right !important;
    font-size: 16px !important;
    text-align: right !important;
    white-space: break-spaces;
    line-height: 30px;
    word-wrap: anywhere;
    
  }
.social-icon 
{
  cursor: pointer;
  margin-left: 10px;
  
}
span.social-icon:hover
{
  color: #af734f;
}
#edit-profile-form_content input::placeholder
{
  color: #25252560 !important;
}
#edit-profile-form_content input::placeholder,#edit-profile-form_content textarea::placeholder
{
  color: #25252560 !important;
}
.social-icon i,.social-icon a:active,.social-icon a:focus
{
  color: #252525 !important;
}
.follow-button
{
  /* border: 2px solid #af734fd1 !important;
  background: #af734fd1 !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  padding: 10px 20px 10px 20px !important;
  font-size: 14px !important; */
 
}
.follow-button:hover
{
  background: #af734f !important;
  /* color: #ffffff !important; */

}

a.followed
{
    /* background: transparent !important;
    color: #af734f !important;
    border: 1px solid #af734f !important; */
}
a.followed :hover
{
    background: #af734f !important;
    color: #ffffff !important;
    border: 1px solid #af734f !important;
}

#message-header img
{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 10px 0px 0px 0px;
}
#message-header p
{
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
#message-header
{
  display: table;
  margin: auto;
  /* height: 24% ; */
  direction: rtl;
  border-bottom: 1px solid #25252526;
  width: 70%;
  transition: transform 0.3s ease-in-out;

}

#message-header.headerHidden
{
  transform: translateY(-100%);
  transition-delay: 0.3s;
  position: absolute;
  left: -9999px;
  top: -9999px;

}
#message-body
{
  height: 80%;
  overflow: auto;
  overflow-y: scroll;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: transparent transparent;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column-reverse;

}

#message-body.headerHidden
{
   
height: 94%;
}

#message-body::-webkit-scrollbar {
  width: 0; /* Hides the scrollbar in Chrome, Edge, and Safari */
}

#message-form
{
  
  direction: rtl !important;
  bottom: 10px;
  position: fixed;
  bottom: 0;
  margin-bottom: 35px;
  background-color: white;
  z-index: 100;
}

.message-col1
{
  width: 15% !important;
  display: flex !important;
  align-items: center;
}
.message-col2
{
  width: 85% !important;
  display: flex !important;
  align-items: center !important;
  font-size: 15px;
  margin-right: 10px;
  margin-left: 10px;
}
.message-col1 img
{
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
}
#message-form .message-col2 
{
  border: 1px solid #eee !important;
  border-radius: 30px !important;
  width: 100% !important;

}


.spinner {
  display: table;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-color: #af734f transparent #af734f transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

#profile-cover
{
  background-position: center center !important;
}
#profile-nav h1
{
  font-size: 18px !important;
  margin-top: 25px;
}
#profile-nav #p-row1
{
  margin-bottom: 30px;
}
.uploadedMedia img , video.uploadedMedia
{
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}
#profile-nav .p-title h4
{
  text-align: right;
}

#message-dialog .p-sidebar-content
{
  padding-bottom: 20px;
  padding-top: 30px;
  overflow: hidden;
}

#uploading
{
  opacity: 0.5;
}

#uploade-completed
{
  opacity: 1;
}

#add-user-to-group_content .row .col-two p.name
{
  margin-right: 30px !important;
  
}
#message-form
{
  position: inherit;
}
#message-form span
{
  margin: 0px;
}
.father-mother-pic
{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0px 5px 0px 5px;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

#user-profile.offline
{
  width: 100% !important;
}



#chat-date
{
  font-size: 12px !important;
}

#message-dialog
{
  width: 40%;

  margin-right: 0px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    z-index: 1000;
    padding: 20px;


}

#reply-banner
{
  background: #0000000a;
  border-radius: 20px;
  padding: 20px 10px;
  margin-top: 20px;
  margin-bottom: 0px;

}
.chat-arrow {
  width: 0;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f1f0f0;
  align-self: flex-start;
  color:#0000000a ;
  
  
}

#uploading img{
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

.dark .p-overlaypanel-content .profile-panel .col-two {

  color: #ffffff;
}


.pi.load-more
{
  margin-top:20px;
  color:#af734f !important;
  font-size:30px;
   cursor:pointer;
}
.pi.load-more:focus,.pi.pi.load-more:hover
{
  background: transparent !important;
}
.online-dot , .online-dot-grey{
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  animation: pulse 1s infinite alternate; /* Add a pulsing animation */
  margin-left: 10px;
}
.online-dot-grey {
  background-color: green;
  margin-right: 10px !important;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
















/*سایز موبایل*/
@media only screen and (max-width: 768px) {

#message-form
{
  position: fixed !important;
}

#message-dialog .p-sidebar-content
{
  padding-bottom: 40px;
  padding-top: 0px;
  overflow: hidden;
}

.lastVisite
{
  font-size: 12px !important;
  color: #495057b8
}

  

#tab1-col2
{
  display: none;
}
#tab1-col1
{
  width: 100%;
}
#tab1-col1 #feed{
  padding: 10px 10px 10px 10px !important;
  width: 100% !important;
}
#edit-profile-form
{
  margin: 0px 10px 0px 10px !important;
}
.p-col1 img {
  width: 80px;
  height: 80px;
}
.badges img {
  width: 60px;
  height: 120px;
}
.badges span
{
  margin: 28px 0px 0px 15px;
}
#profile-cover .pi {
  background: transparent;
  color: black;
  cursor: auto;
}
#profile-nav {
  padding: 0px 5px 20px 0px;
  margin-top: 0px;
}
#profile-nav .p-avatarPic
{
  width: 100px !important;
  height: 100px !important;
  margin-top: -30px !important;
  margin-right: 0px;
}
#p-row2 {
  margin-top: 20px;
  padding-right: 10px;
}
.p-col1 {
  width: 100%;
}
.follow-button {
  padding: 10px 10px 10px 10px !important;
  font-size: 13px !important;
}
.p-follow,.p-message
{
  margin: 0px 10px 0px 0px !important;
  text-align: center;
}
.info-header-col2 .pi
{
  margin-top: -30px;
}
#edit-profile-pic
{
  width: 100px !important;
  height: 100px !important;
}
#profile-cover .pi-camera {
  background: #25252520;
  color: #ffffffa8;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}
.message-col2 {
  margin-right: 10px;
}
#message-form span
{
  margin-top: 10px !important;
}
#profile-nav .pi-user-edit
{
  font-size: 25px !important;
  margin-left: 10px;
}
#profile-nav .p-title p
{
  margin-top: 0px !important;
}
#profile-content #post
{
  margin-bottom: 15px;
}
.follow-button {
  width: 100% !important;
  display: block;
}
.follow-button {
  padding: 10px 5px 10px 5px !important;
}
#message-header {
  padding-top: 0px;
  padding-bottom: 6px;
}

#follow-page-button a
{
  padding: 10px 15px 10px 15px !important
}

#profile-nav .p-col1 .p-title
{
  margin: -20px 10px 0px 0px !important;
}

#about-panel
{
    display: contents;
}
#about-panel #tab1-col2,#tab1-col1 {
  display: flex;
  width: 100%;
}
#about-panel #tab1-col2 .info-box
{
  margin: 0% 10% 5% 10%;
}

#about-panel #tab1-col2 .info-box .pi-pencil
{
  display: none;
}

#profile-tab_content_0
{
padding: 20px 0px 20px 0px;
}

.about-box {
  width: 100%;
}

#tab1-col1 .about-box {
  margin: 0 10% 5% 10% !important;
  width: auto ;
  padding: 20px;
}

#page-description
{
  width: 100%  !important;
}
#message-dialog
{
  width: 100%;
}
#message-dialog .p-sidebar-header 
{
  display: block !important;
  padding-top: 0px;
  padding-bottom: 0px;

}
  
#message-dialog .p-sidebar-close
{
  margin-top: 10px !important;
  box-shadow: none !important;
  background: none !important
}

#message-dialog .p-sidebar-content
{
  margin-top: -40px !important;
}

#message-dialog .p-sidebar .p-sidebar-header {
  padding: 0rem 1rem 0rem 0rem;
}

#message-dialog .p-sidebar-content
{
 
}
#message-form
{
  width: 90%;
}


}

