#error-handler
{
    padding-top: 30px;
    background: #ffffff !important;
    height: 100vh;
    margin-bottom: 100px;
    
}
.App {
    
    background-color: #ffffff;
  }

#error-title
{
    position: absolute;
    margin-left: 45%;
}
#error-title h2,#error-title p
{
    direction: rtl;
}
#error-title p
{
    font-size: 18px !important;
}
#error-pic
{
    background: #ffffff !important;
}
#error-button
{
    position: absolute;
    margin-left: 45%;
    margin-top: -100px;
    
}
#error-button button
{
    
    padding: 10px 20px 10px 20px;
    font-size: 20px;
    background: #af734f;
    color: #fff;
    border: none;
    border-radius: 30px;
    box-shadow: 0 0 30px 0 #af734f82;
    cursor: pointer;
}

/*سایز موبایل*/
@media only screen and (max-width: 768px)
{
    #error-title
    {
        
        display: contents;
    }
    
    #error-pic img
    {
        width: 100% !important;
    }

    #error-button {
    margin-left: 35% !important;
    margin-top: 0px !important;
    }


}