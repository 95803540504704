#detailed-page-dialog_content
{
    padding-top: 10px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
}
#detailed-page-dialog .p-dialog-header
{
    border-bottom: 1px solid #eee !important;
    
}
.detailed-page-dialog label
{
    text-align: right;
    direction: rtl;
    float: right;
}
.field {
    margin-bottom: 20px !important;
    display: grid !important;

  }

  .detailed-page-dialog .p-dropdown-label,.detailed-page-dialog .p-inputtext
  {
    text-align: right !important;
  }

  #edit-profile-form_content .p-dropdown
  {
    text-align: right !important;
  }