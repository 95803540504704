

.tag .button button
{
    margin-left: 6px;
  margin-right: 6px;
  padding: 0 25px;
  height: 41px;
  line-height: 41px;
  border-radius: 20px;
  white-space: nowrap;
  background-color: #ffffff;
  cursor: pointer;
  color: #404040b8;
  box-shadow: 0 0 30px 0 #af734f1f;
  border-color: #c9a65400;
  border-radius: 30px !important;
  transition: transform 350ms;
  font-size: 16px;
    
}

.tag .button button:hover
{
    transform: translateY(-10px) !important;
    background-color: #af734f !important;
  color: #ffffff;
 
}