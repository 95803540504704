.intro-container {
  background-color: transparent;

  position: relative;
  display: flex;
  padding: 0px 100px 0px 100px;
  margin-top: 0px;
  margin-bottom: 50px;
}

.intro-item {
  width: 33.333%;
  position: relative;
  display: inline-block;
  transition: 0.3s ease-in-out;
  text-align: right;
  padding: 30px 50px 0px 50px;
  height: 320px;
  border-radius: 30px;
}
.intro-item:hover {
  background: #ffffff !important;
  box-shadow: 0 0 30px 0 #af734f1f;
}

.intro-item .pi {
  font-size: 40px;
  color: #ae7a00;
  margin-bottom: 0px;
  width: 150px;
  height: 150px;
  opacity: 0.9;
}

/*سایز موبایل*/
@media only screen and (max-width: 768px) {
  .intro-container {
    display: table !important;
    padding: 0px !important;
  }
  .intro-item {
    width: 100% !important;
    margin-bottom: 10px !important;
    text-align: center;
    padding: 0px 50px 0px 50px;
    height: 300px;
  }
  .title,
  .subtitle {
    margin: 0px !important;
    margin-bottom: 10px !important;
  }
 
  
}
