#feed
{
    padding: 20px 60px 0px 0px;
}
#create-post
{
    height: 70px;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    cursor: pointer;
    padding: 0px 20px 0px 20px;
    
}
#feed-col1
{
    position: relative;
    width: 30%;
    align-items: center;
    display: flex;
    direction: ltr;
}
#feed-col2
{
    position: relative;
    align-items: center;
    display: flex;
    direction: rtl;
    width: 60%;
    text-align: right;
    margin-right: 10px;
    color: #25252580;
    font-size: 13px;
}
#feed-col3
{
    position: relative;
    width: 10%;
    align-items: center;
    display: flex;
    direction: rtl;
    
}
#feed-col3 img
{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
#feed-col1 button
{
    background: transparent !important;
    border: none !important;
    color: #25252580 !important;
    padding: 0px !important;
    float: left;
    margin-right: 10px;
    padding: 1px !important;
    border-radius: 0px !important;
    
}
#feed-col1 button:focus
{
    box-shadow: none !important;
    
}
#feed-col1 .pi
{
    font-size: 20px;
}
.create-post-tooltip
{
    font-size: 12px !important;
   
}
#search-section
{
    height: 1000px;
    margin: 0px 60px 0px 0px !important;
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

#search-section ul
  {
    display: flex !important;
    width: 100% !important;
    margin-bottom:0px !important;
  }
  #search-tab li
{
    width: 30% !important;
}
#search-tab ul a
{
    display: table;
    margin: auto;
    width: 100% !important;
}
#search-tab ul , #search-tab li , #search-tab ul a
{
    background: transparent !important;
    font-size: 16px !important;
}

#search-tab ul a:focus
{
    color: #af734f !important;
    border-color: #af734f !important;
    box-shadow: none !important;
}
#search-tab ul a:active
{
    color: #af734f !important;
    border-color: #af734f !important;
    box-shadow: none !important;
}
#search-tab ul a:hover
{
    color: #af734f !important;
    border-color: #af734f !important;
    box-shadow: none !important;
}
#search-section .p-tabview .p-tabview-panels
{
    margin-top: 10px !important;
    border-radius: 5px ;
}
#search-section .p-tabview-nav-container
{
    background: #ffff !important;
    /* border-radius: 5px !important; */
}
#search-section .p-tabview.p-component
{
    background: #eee !important;
    border: none !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: #af734f !important;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    
    border-color: #af734f !important;
    
  }


  #shop-section i#plus-icon::before
  {
    
    display: inline-block;
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; /* Make sure the clickable area is on top */
    margin-top: -40px;
  
  }
  #shop-section i#plus-icon
  {
    position: relative; /* Set the parent element as the reference for absolute positioning */
    z-index: 0; /* Make sure the parent element is behind the clickable area */
  }


  .search-box-wraper
  {
    border-radius: 30px !important;
    background: transparent !important;
    width: 90% !important;
    height: 40px !important;
    margin-top: 20px !important;
    border:none !important;
    direction: rtl !important;
    font-size: 15px !important;
    display: flex;
  }
  .search-box
  {
    background: transparent !important;
    width: 100% !important;
    height: 40px !important;
    border: none !important;
    font-size: 16px !important;
  }
  .search-box:active, .search-box:focus, .search-box:hover
  {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  #search-section
  {
    /* border-radius: 5px !important; */
  }

  .search-row
  {
    padding: 20px 20px 0px 20px !important;
    margin-bottom: 0px !important;
    cursor:pointer !important;
  }
  .search-row:hover
  {
    background: #eee !important;
  }
  .search-row img  , .search-row video
  {
    width: 60px !important;
    height: 60px !important;
  }
  .search-row .row .col-one a
  {
    padding: 10px 3px 10px 3px !important;
  }
  #search-section .p-tabview .p-tabview-panels {
    
    padding: 0px !important;
  }

  #search-section .p-tabview-panel h5
  {
    padding: 20px 30px 10px 10px !important;
    text-align: right !important;
    margin: 0px !important;
  }
  .create-new-post-dialog
  {
    display: flex;
    direction: rtl;
    padding-top: 20px;
  }

.new-post-dialog .p-dialog-header {

  border-bottom: 1px solid #2525251f !important;
  padding: 1rem !important;
}
#notification-section
{
  padding: 0px 60px 0px 0px;
  background: transparent !important;
  height: fit-content;
}
#shop-section
{
  margin: 0px 60px 0px 0px 
}
#shop-search-box
{
    /* background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px; */
    padding: 0px 0px 40px 0px;
}
#search-tab .p-tabview-nav
{
  background: #ffffff !important;
    border-radius: "5px" !important;
    margin: 0px 0px 0px 0px !important;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px; */
}
#shop-search-box .p-tabview-panels
{
  margin-top: 0px !important;
  background: transparent !important;
    
}
#shop-section #post
{
  margin-bottom: 20px !important;
}

.loader-container {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: transparent;
  z-index: 1;
}

.spinner {
  display: table;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-color: #af734f transparent #af734f transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

#upload-progress span
{
  margin-bottom: 20px;
  direction: rtl;
  display: flex;
  align-items: center;
}
#upload-progress img
{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  
  
}
#upload-progress .row1
{
  display: flex;
  width: 8%;
  
}
#upload-progress .row2
{

  width: 92%;

}

#shop-section #search-tab_header_2 .p-tabview-title
{
  /* font-size: 20px !important;
  background: #af734f !important;
  padding: 1px 10px 1px 10px !important;
  border-radius: 50% !important;
  color: #ffffff !important; */
}
#shop-section #search-tab_header_2 
{
  /* margin-top: -1px; */
}

#search-tab_content_1 #suggestion-box .row .col-three 
{
  height: 80px;
}

#search-tab_content_1 #suggestion-box .row .col-three img,#search-tab_content_1 #suggestion-box .row .col-three video
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#plus-icon::before
  {
    background: #af734f !important;
    border-radius: 50% !important;
    padding: 8px !important;
    color: #ffffff !important;
    cursor: pointer;
  }

  #search-tab_content_5 #suggestion-box .row .col-one
  {
    -webkit-line-clamp: 4!important;
    height: 120px;
    line-height: 30px!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    white-space: break-spaces;
    -webkit-box-orient: vertical;
  }
  #search-tab_content_5,#search-tab_content_4,#search-tab_content_3,#search-tab_content_2,#search-tab_content_1,#search-tab_content_0
  {
    padding-bottom: 100px !important;
  }

  #shop-search-box .p-tabview-panels
  {
    padding: 1rem 0rem 1rem 0rem;
  }

  .dark .search-row:hover
  {
    background: #464646c7 !important;
  }



/*سایز موبایل*/
@media only screen and (max-width: 768px)
{
  #feed,#notification-section,#search-section,#shop-section {
    padding: 0px 0px 50px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
  #create-post
  {
    display: none;
  }
  #user-profile #create-post
  {
    display: flex;
  }
  #user-profile #create-post #feed-col2 {
    margin-right: 20px;
  }
  .search-box-wraper {
    border-radius: 10px !important;
    width: 100% !important;
    margin-top: 10px !important;
    background: transparent !important;
  }
  
  #search-tab .p-tabview-nav {
    margin: 0px 0px 0px 0px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15) !important;
  }
  #shop-section .p-tabview .p-tabview-panels {
    padding: 0px !important;
  }

  #upload-progress .row1
  {
    width: 12%;
    margin-right: 10px;
  }
  #upload-progress .row2 {
    width: 80%;
  }

  #search-tab_content_1,#search-tab_content_0,#search-tab_content_2,#search-tab_content_3,#search-tab_content_4,#search-tab_content_5
  {
    padding-bottom: 100px !important;
  }

  #shop-section i#plus-icon::before
  {
    
    display: inline-block;
    width: fit-content;
    height: fit-content;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; /* Make sure the clickable area is on top */
    margin-top: -40px;
  
  }
  #shop-section i#plus-icon::before
  {
  
    left: 30px;  
    margin-top: -35px;
  
  }


}
