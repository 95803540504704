

body,h1,h2,h3,h4,h5,h6,span,a,p,div,input,option,figcaption,li,ul,label,button,textarea
{
font-family: "dana" !important;
}
span:before
{
  font-family: 'primeicons' !important;
}
h1,h2,h3,h4,h5,h6,th
{
font-family: "dana-bold" !important;
font-weight:10 !important;
color: #404040db  !important;
margin-top: 0px;
}


.App {
  text-align: center;
  height: 100%;
  background-color: whitesmoke !important;
}

.p-button
{
  background: #af734f ;
  border: 1px solid #af734f ;
  border-radius: 30px !important;
  direction: rtl !important;
}
.p-button:enabled:hover
{
  border-color: #af734f !important;
}
.p-sidebar-content .p-button
{
  border: 1px solid #af734f !important;
}
#login-form button
{

  border: 1px solid #af734f !important;
}

.filled-button
{
  background: #af734f !important;
  border-color: #af734f !important;
}



.add_product_form
{
  padding: 30px !important;
  padding-top: 10px !important;
  text-align: right !important;
  width: 100% !important;
  direction: rtl !important;
  table-layout: fixed !important;
  background-color: #e5e5e5 !important;
  border-radius: 10px;
}
.add_product_field
{
  list-style-type: none !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  direction: rtl !important;
    width: 100% !important;
}
.add_product_field input
{
  width: 100% !important;
}

.p-inputnumber,.p-dropdown,.p-inputtextarea,.p-fileupload,.p-password 
{
  width: 100% !important;
}
.label
{
  margin-bottom: 10px !important;
}
.p-dropdown-item
{
  text-align: right !important;
}
.add_product_form th
{
  margin-bottom: 40px !important;
}
.p-dialog-title
{
  text-align: right !important;
  margin-right: 10px !important;
}
.add_product_button
{
  background-color: brown !important;
  border-color: white !important;
}
.new_product
{
  padding-right: 300px !important;
  padding-left:  300px !important;
  padding-top:  50px;
  padding-bottom: 50px;
}
.login input
{
  width: 100% !important;
}
.p-dialog .p-dialog-content {
    padding: 0 4rem 2rem 4rem !important;
    text-align: center;
}
.border-red-400 {
  border-color: var(--red-400) !important;
}

.container {
  position: relative;
  width: 50%;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%)
}

.container:hover .image {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #5c858b;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.dropdown{
  width: 8rem !important;
  margin-right: 5rem;
}
.categories
{
  margin-top: 80px;
  padding-right: 50px;
  padding-left: 50px;
  list-style-type: none;
}
.categories li
{
  float: left;
}
.main-content
{
  display: inline-block;
  padding-bottom: 0px;
  width: 100%;
}
.new-products
{
  margin-top: 40px;
  padding-right: 50px;
  padding-left: 50px;
  list-style-type: none;
}
.new-products li
{
  float: left;
}
.category-list
{
  display: inline-block;
  background-color: #ffffff;
  padding-bottom: 100px;
  width: 100%;
  align-items: center;
}
.new-product-list
{
  display: inline-block;
  background-color: rgb(247, 244, 244);
  padding-top: 100px;

}
.categories
{
  display: inherit;
}

h2
{
  font-size: 35px !important;

}

h3
{
  font-size:  25px !important;
}
h4
{
  font-size: 16px;
}
h5
{
  font-size: 14px;
}
h6
{
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
}
p{
  color:#404040b8;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link span , .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #af734f !important;
  border-color: #af734f !important;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #af734f !important;
}
.pi
{
  vertical-align: middle;
}
a:link { text-decoration: none !important; }
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #af734f !important;
  background: #af734f !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #af734f !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #af734f !important;
  background: #af734f !important;
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #af734f !important;
  background: #af734f !important;
}
a{
  cursor: pointer !important;
}
a:hover{
  color: #af734f;
}

.p-tabview-nav a:focus
{
  box-shadow: none !important;
  
}
#root
{
  background: whitesmoke !important;
  height: 100% ;
}

.p-button:focus {
  box-shadow:none !important
}

body
{
  background-color: whitesmoke;
}

#pr_id_6_content
{
  padding-bottom: 100px !important;
}
.p-dialog-header-close:focus,.p-dialog-header-close:hover
{
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

.pi-reply
{
  transform: scaleX(-1);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden; /* Clip the image during animation */
}


.zoom-in {
  animation: zoomIn 0.3s ease forwards;
}

.p-dialog .p-button {
  color: #ffffff ;
  background: #af734f !important;
  border: 1px solid #af734f !important;
}
.p-dialog .outline-button
{
  background: transparent !important;
  border: 1px solid #ffffff !important;
}

.p-overlaypanel-content .p-button{
  color: #ffffff;
  background: #af734f !important;
  border: 1px solid #af734f !important;

}
.divider
{
  color: #27272729;
  width: 70%;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;  

}
@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}


/*سایز موبایل*/
@media only screen and (max-width: 768px)
{
  #header{
    padding: 0px !important;
  }

  .new-product-list {
    
    padding-top: 30px !important;
    padding-bottom: 50px !important;
  }
  h3
  {
    font-size: 25px !important;
  }
  .p-dialog .p-dialog-content {
    padding: 0 2rem 2rem 2rem !important;
  }

  p
  {
    font-size: 16px !important;
    word-wrap: break-word;
  }
  span
  {
    word-wrap: break-word !important;
  }
  .App {
    background-color: transparent !important;
  }

}





/* dark theme ************************************************************************************************************/
.dark.App
{
  background-color: #000000 !important;

}
.dark #mobile-nav1 {
  background: #272727;
 
}


.dark #dashboard,
.dark
{
  background-color: #000000;
}


.dark .p-card , 
.dark #post .p-fieldset,
.dark .p-sidebar,
.dark #search-section,
.dark .p-tabview.p-component ,
.dark .p-tabview-nav-container,
.dark .p-tabview-panels,
.dark #nav-bar-panel,
.dark #suggestion-box-wraper,
.dark #create-post,
.dark #nav-bar-and-pannel,
.dark #nav-icons,
.dark .p-overlaypanel,
.dark #profile-nav,
.dark #profile-content,
.dark #profile-content .p-tabview.p-component ,
.dark #profile-content .p-tabview.p-component 
{
  background: #272727 !important;
}


.dark .pi , .dark p , .dark h1 , .dark h2 , .dark h3 , .dark h4,.dark h5,.dark h6,
.dark #create-post p , .dark div
{
  color: #ffffff !important;
}

.dark #post-cat .cat-col2 ,
 .dark input , 
 .dark input::placeholder ,
.dark #mobile-nav1 .pi-search,
.dark .p-inputtext::placeholder,
.dark span,
.dark #profile-nav .p-col1 button


{
  color: #ffffff80;
  
}

.dark #new-comment span.row2 {
  border: 1px solid #ffffff80 !important;
}
.dark #post-content .view-more-button {
  font-family: "dana" !important;
}
.dark #post-pic img 
{
  border:none;
  background: #272727 !important;
}
.dark .panel-row .col-two
{
  color: #ffffff !important;
}
.dark .panel-row2:hover {
  background: #eeeeee52;
}

.dark .panel-row2 .col-two 
{
  color: #ffffff;
}

.dark .panel-row2 .pi {
  background: #af734f4f;
}

.dark #post-cat .cat-col1 span
{
  color: rgb(175, 115, 79) !important;
}
.dark #suggestion-box a.button {

  background: transparent;
  color: #ffffff80 ;
  border: 1px solid #ffffff80;
  border-radius: 30px;
}
.dark .search-box-wraper {
  /* border: 1px solid #ffffff80 !important; */
}
.dark .search-box-wraper i{
  color: #ffffff80 !important;
}
.dark #repost-header span.name
{
  color: #ffffff !important;
}
.dark #post-reaction span
{
  color: #ffffff80 !important;
}
#mobile-sidebar-dark
{
  background: #272727 !important;
  color: #ffffff !important;
}
#mobile-sidebar-dark p
{
  color: #ffffff !important;
}
.dark .info-box,.dark .about-box 
{
  background: #272727 !important;
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark .p-tabview-panels
{
  background-color: #000000 !important;
}
.dark .p-card
{
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark .p-tabview .p-tabview-nav {
  
  border-bottom: 1px solid #ffffff80;
}
.dark #profile-content .p-tabview .p-tabview-panels {
  margin-top: 0px !important;
  padding-top: 10px !important;
}
.dark .badges span,.dark .badges .pi
{
  color: #272727 !important;
}
.dark .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  
  border-color: transparent;
}
.dark #profile-tab ul a:active span
{
    color: #af734f !important;
   
}
.dark .info-box-content span
{
  color: #ffffff80 !important;
}
.dark #addConnection,.dark #addConnection .pi
{
  color: #af734f !important;
}
.dark #search-tab .p-tabview-nav ,
.dark #search-section .p-tabview-panels , 
.dark #notification-panel .p-tabview-panels , 
.dark #notification-panel,
.dark #notification-panel .p-tabview .p-tabview-nav ,
.dark .p-tabview .p-tabview-nav li .p-tabview-nav-link 
{
  
  background: #272727 !important;
  border-radius: 0px !important;
  
}

.dark #search-section .p-tabview.p-component {
  background: #272727 !important;
}
.dark .notif-row .notif-col3 span
{
  color: #af734f !important;

}
.dark #profile-nav .p-col1 button
{
  color: #ffffff80 !important;
}
.dark #profile-content #about-panel img
{
  /* filter: brightness(50%); */
  
}

.dark #shop-search-box-header
{
  background-color: #272727 !important;
}
.dark .new-post-dialog 
{
  background-color: #000000 !important;
}
.dark.p-dialog
{
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark .p-dialog-header
{
  background: #272727 !important;
  color: #ffffff !important;
  /* border-bottom: 1px solid #ffffff80 !important; */

}
.dark .p-dialog-content
{
  background: #272727 !important;
  color: #ffffff !important;
}
.dark .p-dialog-content p
{
  color: #ffffff80 !important;
}
.dark .new-post-form .VideoInput 
{
  background: #000000 !important;
  border: 1px solid #ffffff80;
}
.dark .new-post-form textarea,.dark .new-post-form #chip-input input
{
  background: #000000 !important;
}
.new-post-form #chip-input input:h
{
  border: none;
}



.dark .p-inputtext,
.dark .p-dropdown 
{
  background: #272727 !important;
  border: 1px solid #ffffff80 !important;
      color: #ffffff !important;
      
}
.dark button span
{
  color: #ffffff;
}
.dark .css-13cymwt-control 
{
  background: #272727;
}
.dark .settings-box
{
  background: #272727 !important;
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark .settings-box input
{
  border: 1px solid #ffffff80 !important;
}
.dark .p-tabview .p-tabview-nav {
  background: #272727 !important;
}

.dark .p-overlaypanel 
{
  background: #272727 !important;
}
.dark #create-post #feed-col2
{
  color: #ffffff80;
}

.dark #search-panel .panel-row3 .col-two
{
  color: #ffffff;
}
.dark #shop-search-box .p-tabview-panels , .dark #shop-section
{
  background: #000000 !important;
}
.dark #post .view-more-button 
{
  color: #ffffff80 !important;
}
.dark #feed
{
  background-color: #000000;
}
.dark #new-comment input
{
  border-top: 1px solid #ffffff80 !important;
  border-right: 1px solid #ffffff80 !important;
  border-bottom: 1px solid #ffffff80 !important;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  
}
.dark .search-box-wraper .p-inputtext {
  border: none !important;
  
  }
.dark #new-comment #content::placeholder
{
  color: #ffffff80 !important;
}
.dark .p-tabview-title
{
  color: #ffffff;
}

.dark #notification-panel .p-tabview .p-tabview-nav li .p-tabview-nav-link 
{
  border-color: #ffffff80 !important;
}
#suggestion-box.dark .row .col-two p.name
{
  color: #ffffff !important;
}

#message-dialog.dark,.dark #message-form
{
  background: #272727;
}
.dark .p-splitbutton{
  background: transparent !important;
}
.dark .p-dialog-footer {
  background: #272727 !important;
}
#dark_menu
{
background-color: #272727;

}
.p-menu-overlay 
{
  padding: 0px !important;
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
#dark_menu .p-menuitem-link span
{
  color:#ffffff !important;
}
.dark.p-overlaypanel{
  background: #272727;
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark .new-post-form textarea
{
  color: #ffffff;
}
.dark .new-post-form textarea::placeholder
{
  color: #ffffff80;
}
.dark .new-post-form #content .p-inputtextarea 
{
  border: 1px solid #ffffff80 !important;
}
.dark #upload-buttons p
{
  color: #ffffff !important;
}
.dark #delete-button
{
  border: 1px solid #ffffff80 !important;
}
.dark #delete-button i
{
  color: #ffffff !important;
}
.dark #new-post-category .p-inputtext {
  color: #ffffff !important;
}
.dark #new-post-category .p-dropdown-trigger-icon
{
  color: #ffffff80 !important;
  font-size: 15px;
}
.dark .p-dropdown-panel .p-dropdown-items .p-dropdown-item
{
  background: #272727 !important;
  color: #ffffff !important;
}
.dark .p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover
{
  background: #af734f !important;

}
.dark #settings span.settings-col2
{
  color: #ffffff !important;
}
.dark .p-dropdown-items
{
  padding: 0px !important;
}
.dark input , .dark textarea
{
  border:  1px solid #ffffff80 !important ;
  color: #ffffff !important;
}
.dark input , .dark textarea::placeholder
{
  color: #ffffff80 !important;
}
.dark #account-tab li .p-tabview-nav-link 
{
  border-color: #ffffff80 !important;
}

.dark .new-page-insettings .settings-col1 .pi,.dark .new-page-insettings .settings-col2
{
  color: #af734f !important;
}
.dark #notification-section
{
  background: #000000 !important;
}
.dark #post-reaction .heart-icon-false path
{
  stroke: rgb(255 255 255 / 100%) !important;
}
.dark .notif-row .notif-col2 span
{
  color: #ffffff !important;

}
.dark span.message-col2
{
  color: #ffffff !important;
}
.dark span.message-col2 img,.dark span.message-col2 video
{
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark span.message-col2 input
{
  border: none !important;
}
.dark #message-header {
  border-bottom: 1px solid #ffffff80;
}
.dark .pi-info-circle{
  color: #ffffff80 !important;
}
.dark #repost-header {

  border-color: #ffffff80 !important;
}
.dark #post-reaction .save-icon path
{
  stroke: rgb(255 255 255 / 100%);
  fill: rgb(255 255 255 / 100%);

}
.dark p.job
{
  color: #ffffff80 !important;
}
.dark #profile-nav .p-avatarPic
{
  background: #272727 !important;
    box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark #post-reply-banner
{
  background: #464646c7 !important;
}
.dark .post-chat-arrow 
{
  border-top: 10px solid #464646c7 !important;
}
.dark .reply-to-name{
  color: #ffffff !important;
}
.dark .reply-to-text
{
  color: rgb(175 115 79) !important;
}
.dark #reply-banner
{
  background: #464646c7 !important;
}
.dark .chat-arrow
{
  border-top: 10px solid #464646c7 !important;
}
.dark #reply-banner span
{
  color: #ffffff80 !important;
}
.dark .lastVisite .pi
{
  color: #ffffff80 !important;
}
.dark .pi-tag
{
  color: #af734f !important;
}
.dark .at-text
{
  color: #ffffffe6 !important;
}
.dark .at-name
{
  color: #ffffff80 !important;
}

.dark #profile-content .about-box-content span,.dark #profile-content .about-box-content .pi,
.dark #profile-content span.about-box-content,
.dark #profile-content .about-box-content p,
.dark #profile-content .about-box-content a
{
  color: #ffffff !important;
}
.dark #profile-content .pi-calendar
{
  color: #ffffff80 !important;
}
.dark p#chat-date
{
  color: #ffffff80 !important;
}
.dark #comment-date
{
  color: #ffffff80 !important;
}
.dark #manager-panel
{
  background: #000000 !important;
}
.dark #manager-visit-site-text
{
  color: #af734f !important;
}
.dark #manager-panel .pi
{
  color: #af734f !important;
}
.dark #manager-panel .title
{
  color: #ffffff !important;
}
.dark #manager-panel .subtitle
{
  color: #ffffff80 !important;
}
.dark #manager .intro-item {
  background: #272727;
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark #manager .p-datatable .p-datatable-tbody > tr {
  background: #27272A;
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;
}
.dark #manager .p-column-title
{
  color: #ffffff !important;
}
.dark #manager .p-datatable td{
  color: #ffffff80;
}
.dark #profile-content .pi-user {
  color: #ffffff80 !important;
}
.dark  .css-qbdosj-Input:focus
{
  background: #272727;
  color: #272727;
}
.dark #error-handler h2,.dark #error-handler p,.dark #error-handler span
{
  color: #272727 !important;
}
.dark #chip-suggestions 
{
  background: #272727;
  box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;

}
.dark #chip-suggestions .user-item .user-name 
  {
    color: #ffffff !important;
  }

  .dark #suggestion-box .row .col-two span.name
  {
    color: #ffffff;
  }
  .dark .new-post-form {

    background-color: #272727;
  }
  .dark .edit-post .new-post-form
  {
    background-color: #272727;
  }
  .dark #new-post-category .p-dropdown {

    background: #272727 !important;
  }
  .dark #suggestion-box .row .col-three img,#suggestion-box .row .col-three video
  {
    /* box-shadow: rgba(255, 255, 255, 0.71) 1px 0px 5px 1px; */
  }
  #dark_menu li.p-menuitem:hover,#dark_menu .p-menuitem-link:hover,.dark .pi:hover
  {
    background-color: #000000 !important;
  }

  .dark .main-layout-form-wrap,
  .dark .main-layout 
  {
    background: #000000 !important;
    background-color: #000000 !important;
  }

 .dark .main-layout-form-wrap .p-tabview.p-component
 {
  background-color: #000000 !important;
 }

 .dark .main-layout-form-wrap .p-tabview-nav,
 .dark .main-layout-form-wrap .p-tabview .p-tabview-nav li .p-tabview-nav-link
 {
  background-color: #000000 !important;
 }

 .dark .main-layout-form-wrap .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-color: #ffffff80 !important;

 }
 .dark .main-layout-form-wrap .p-tabview .p-tabview-panels ,
 .dark .main-layout-form-wrap label
 {
  color: #ffffff !important;
}

.dark #manager .intro-item:hover,
.dark .nav-bar-item,
.dark #nav-icons
 {
  background: #000000 !important;

}
.dark .nav-bar-item:hover
{
  background-color: #272727 !important;
}

.dark #searchbox
{
  background: #272727;
  border: 1px solid #ffffff11;
  box-shadow: 0 3px 5px rgba(255 255 255 / 50%) !important;

}


#post-content h1 {
  font-size: 1.5em !important;
  line-height: 50px !important;
}

#post-content h2 {
  font-size: 1.2em !important;
  line-height: 30px !important;
  
}

#post-content h3 {
  font-size: 1em !important;
  line-height: 30px !important;
  
}

.p-confirm-dialog-reject.p-button
{
  color: #af734f;
  background: transparent !important;
}

.p-button.p-button-text {
  color: #af734f;
}



/*سایز موبایل*/
@media only screen and (max-width: 768px)
{

  .dark #nav-icons
  {
    background-color: #000000 !important;
    box-shadow: 0px 0px 5px rgb(255 255 255 / 50%) !important;

  }

  .dark .search-box-wraper {
    border: none !important;
}
#root
{
  height: 100vh ;
}
.dark .new-post-form {

  background-color: #000000;
}


}



