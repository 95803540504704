.new-post-form
{
  padding: 0px 50px;
  padding-bottom: 100px;
}
.edit-post .new-post-form
{
  padding: 0px 10px;
  
}
#new-post-profile
{
  position: relative;
  align-items: center;
  display: flex;
  width: 10%;
  direction: rtl;

}
#new-post-profile img
{
  width: 40px;
  height: 40px;
  border-radius: 50%;
 
}
#new-post-name
{
  position: relative;
  align-items: center;
  display: flex;
  width: 40%;
  direction: rtl;
  padding-right: 10px;

}
#new-post-category
{
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
  direction: rtl;
}

#new-post-category .p-inputtext
{
    font-size: 10px !important;
    color: #000000 !important;
}
#new-post-category .p-dropdown
{
    background: #eee !important;
}
#new-post-category .p-dropdown:focus
{
    box-shadow: none !important;
    border: none !important;
}
#new-post-category .p-dropdown:active
{
    box-shadow: none !important;
}

li.p-dropdown-item
{
    font-size: 16px !important;   
}



#content .p-inputtextarea
{
    margin-top: 0px;
    direction: rtl;
    font-family: "dana" !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    font-size: 16px !important;
}

.media-upload
{
    display: block;
    float: right;
    width: 100%;
}
.media-upload #upload-pic
{
    display: flex;
    position: relative;
    align-items: center;
    width: 50%;
}

.p-editor-container,.p-editor-container::placeholder,.ql-editor
{
  direction: rtl;
  text-align: right !important;
}

#post-content img
{
  width: 100%;
}

.ql-picker-label::before
{
  margin-right: 20px;
  font-size: 20px;
  line-height: 15px;
}
.media-upload #upload-video
{
    display: flex;
    position: relative;
    align-items: center;
    width: 50%;
}
.media-upload .p-button-label
{
  margin-right: 10px;
}

.p-fileupload-buttonbar
{
direction: rtl !important;
text-align: right;
}
.media-upload .p-button .p-button-icon-left {
    margin-right: 0px;
    float: right !important;
}
.media-upload .p-button.p-fileupload-choose {

    background: transparent !important;
    color: #495057 !important;
    border: none !important;
    padding: 10px 0px 10px 15px !important;
    
}
.media-upload .p-button.p-fileupload-choose:focus {

    box-shadow: none !important;
    border: none !important;
}
.media-upload .p-button.p-fileupload-choose .pi{
    font-size: 20px !important;
    color: #495057 !important;
    /* sd */
}
#submit-section
{
    display: flex;
    float: left;
    margin-top: 20px;
}

#submit-section .p-button:focus
{
    box-shadow: none !important;
}
.uploaded-pic
{
    margin-top: 20px !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 10px !important;

}
.media-upload div.p-fileupload-row div:nth-child(2),.media-upload div.p-fileupload-row div:nth-child(3),.media-upload div.p-fileupload-row div:nth-child(4)
{
    display: none !important;
}
.media-upload div.p-fileupload-row div:nth-child(1)
{
    padding: 0px !important;
}
.media-upload div.p-fileupload-row div:nth-child(1) img
{
    width: 100%;
    height: 100%;

}
.p-fileupload-content
{
    padding: 0px !important;
}

.media-upload div.p-fileupload-buttonbar button:nth-child(2)
{
    display: none;
}
.media-upload div.p-fileupload-buttonbar button:nth-child(3)
{
    background: transparent !important;
    color: #495057  !important;
    border-color: #495057  !important;
    float: left !important;
    font-size: 12px !important;
}
.media-upload div.p-fileupload-buttonbar button:nth-child(3):focus
{
    box-shadow: none !important;
}
.media-upload div.p-fileupload-buttonbar button:nth-child(3) .pi
{
    font-size: 12px !important;
}
.new-post-form .p-mention {
    display: block !important;
    
  }
  .MuiLinearProgress-colorPrimary {
    background-color: #af734f2b !important;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: #af734f !important;
  }

  textarea#repost-content
  {
    border: 1px solid #00000021 !important;
    padding: 10px !important;
    direction: rtl !important;
    border-radius: 10px !important;
    height: auto !important;
  }

  .tag-user .pi
  {
      border-radius: 50%;
      padding: 10px;
      color: #af734f;
      border: 2px solid #af734f;
      cursor: pointer;
      color: #af734f !important;
  }
  .tag-user span{
    margin-right: 0px;
  }
  .new-post-form #submit-section button
  {
    margin-bottom: 10px;
    padding: 10px 30px;
    
  }

  #tags-area , #tag-users-area
  {
    transition: all .5s ease-in-out;
    overflow: hidden;
   
    
  }

 

  .p-mention-panel {
    top: 40px !important;
    left: 0 !important;
  }

  #new-post-header
  {
    direction: rtl;
    margin-bottom: 10px;
    
    margin-top: 30px;

  }
  #new-post-header h4
  {
    text-align: right;
    margin-bottom: 0px;;
  }
  #new-post-header span
  {
    display: flex;
    align-items: center;
  }
  #new-post-header .pi
  {
    margin-left: 10px;
    font-size: 20px;
  }
 
  #is-not-uploading
{
  background: #af734f !important;
  border-color: #af734f !important;
}
#is-uploading
{
  border-color: #af734f;
}

.new-post-form .p-disabled
{
  opacity: 1 !important;
}
.new-post-form button
{
  padding-left: 30px;
  padding-right: 30px;
}


.dark .p-editor-container .p-editor-toolbar ,.dark .p-editor-container .p-editor-content .ql-editor
{
  background: #000;
}

.dark .p-editor-container .p-editor-content.ql-snow ,.dark .p-editor-container .p-editor-toolbar.ql-snow 
{
  border: 1px solid #dee2e64a;
}

#toolbar {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}

#new_article
{
  padding: 100px 150px;
}
#new-article-header
{
  display: flex;
  direction: rtl;
}

.fileContainer {
  
  box-shadow: none !important;
}

.p-error {
  color: #e24c4c;
}

#keyword-selections
{
  display: flex;
  margin: 30px 0px 20px 0px;
  flex-wrap: wrap;
  gap:10px;
}

#keyword-selections .user-item
{
  background-color: #af734f61;
  color: #272727b0;
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 13px;
}

  /*سایز موبایل*/
@media only screen and (max-width: 768px)
{

  #new-post-name {
    padding-right: 20px;
    width: 30% !important;
  }

  #new-post-name {
    width: 100% !important;
    margin-right: 10px;
  }

  #new_article {
    padding: 50px 0px;
  }

  #toolbar {
    display: block;
  }
}