.logo {
  width: 35% !important;
}

.logo p {
  color: #0f0b0bc2 !important;
  font-size: 1.8rem;
  font-weight: bold;
  display: none;
}
.logo img {
  width: 70px;
  height: 70px;
}
#header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: 1px solid #00000021;
  padding: 0 10%;
}
.login {
  width: 35% !important;
}

.login-button {
  background-color: transparent !important;
  font-size: 18px !important;
  cursor: pointer !important;
  box-shadow: 0 0 30px 0 #af734f1f;
  color: #af734f !important;
  border-color: #af734f !important;
  border-radius: 30px !important;
  padding: 10px 30px 10px 30px !important;
  font-weight: bold !important;
  border: 1px solid !important;
  color: #af734f !important;
  font-size: 1rem;

}
.register-button {
  background-color: #af734f !important;
  font-size: 18px !important;
  cursor: pointer !important;
  box-shadow: 0 0 30px 0 #af734f1f;
  color: #ffffff !important;
  border-color: #c9a65400 !important;
  border-radius: 30px !important;
  padding: 10px 30px 10px 30px !important;
  font-weight: bold !important;
}
#header nav {
  width: 30% !important;
}
#header ul {
  list-style: none;
  margin: 0;
}
#header li {
  float: right;
  margin-right: 0px !important;
}
.login li {
  float: left !important;
}
#header a {
  text-decoration: none;
  font-size: 1.1rem;
  color: white;
}
#header a:hover,
#header a.active,
#header a:active {
  color: #81adb3;
}
.badge {
  background-color: red;
  color: white;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}
.logo-image {
  margin-top: 0px;
}
button.login-dialog {
  margin-top: 20px;
  padding: 10px 110px 10px 110px;
}
.login button:focus
{
  box-shadow: none !important;
}
.sticky-menu
{
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  
  /* transition:  all 0.3s ease-in-out; */
  
  position: relative;
  z-index: 1000;
}

.sticky-menu.active
{
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transition:  transform 0.3s ease-in-out;
  transform: translateY(100px);
  z-index: 1000;
  
  
  
}

/*سایز موبایل*/
@media only screen and (max-width: 768px) {
  .logo {
    width: 100% !important;
    margin-right: 10px !important;
  }
  .logo line-height {
    margin-top: 0px !important;
  }
  .logo p {
    font-size: 18px !important;
  }
  .login .p-button {
    padding: 10px;
    font-size: 16px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .login {
    width: 100% !important;
    margin: 0px !important;
    margin-top: 15px !important;
    padding-left: 20px !important;
    
  }
  #header li {
    margin-right: 0px !important;
  }

  .logo img {
    width: 60px !important;
    height: 60px !important;
    margin-top: 15px;
    margin-right: 10px;
  }
  .logo-image {
    margin-top: 0px !important;
  }
  .logo {
    width: 30% !important;
  }
  #header {
    padding-bottom: 33px !important;
    border-bottom: 1px solid #00000021;
    padding-top: 15px !important;
  }

  #manager .login-button
  {
    font-size: 14px !important;
    padding: 10px !important;
  }
}
