.VideoInput
{
    background: #f8f9fa;

border: 1px solid #dee2e6;
  border-bottom-color: rgb(222, 226, 230);
  border-bottom-style: solid;
  border-bottom-width: 1px;
color: #343a40;
border-bottom: 0 none;
border-top-right-radius: 6px;
border-top-left-radius: 6px;
}
#upload-buttons
{
    padding: 1.25rem;
    direction: rtl;
    display: flex;
    align-items: center;

}
#upload-buttons p, #upload-buttons .pi 
{
    background: transparent !important;
    border: none;
    font-size: 16px;
    color: #495057;
    cursor: pointer;
}
#upload-buttons .pi 
{
    margin-left: 10px;
}
#delete-button
{
    float: left;
    border: 1px solid #343a408c  !important;
    border-radius: 30px !important;
    padding: 10px 20px 10px 20px;
    color: #343a408c  !important;
    direction: rtl;
    font-size: 13px !important;
}
#delete-button .pi
{
    color: #343a408c !important;
    font-size: 13px !important;
    margin-left: 10px;
}

  /*سایز موبایل*/
  @media only screen and (max-width: 768px)
  {
#upload-buttons p, #upload-buttons .pi {
    
    font-size: 14px !important;
   
  }
  #delete-button .pi {
    font-size: 11px !important;
  }
  #delete-button {
    font-size: 11px !important;
  }
  
}