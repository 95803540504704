#manager
{
    direction: rtl !important;
    display: flex;
    height: 100vh;
}
#edit-user-dialog_content .field
{
    text-align: right !important;
}

#manager h2,#manager h3{
  margin-top: 30px !important;
}

#manager-panel
{
    background: #eee;
    width: 94%;
    height: fit-content;
}
#manage-files
{
    
    height: 100%;
}
.manager-table
{
    margin: 0px 50px 100px 50px;
}

#manager .pi-angle-right::before {
    content: "\e931";
  }
  #manager .pi-angle-left::before {
    content: "\e932";
  }
  #manager .pi-angle-double-left::before {
    content: "\e92e";
  }
  #manager .pi-angle-double-right::before {
    content: "\e92d";
  }

  #manager .intro-item
  {
    cursor: pointer;
    text-align: center;
    border: 1px solid #0000001c;
    margin: 10px 10px 10px 10px;
  }
  #manager .intro-item .pi
  {
    height: 50px;
    width: 50px;
  }
  #manager .intro-container
  {
    margin: 60px 0px 30px 0px;
  }
  
  #manager .intro-item {
    padding: 30px 0px 0px 0px !important;
    background: #fff;
  }
  #manager .intro-item:hover
  {
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.35);
  }

  .p-column-filter-menu {
    margin-left: inherit !important;
  }
  #manage-logins
{
  height: 100vh;
  padding: 20px;
}
#manage-logins label
{
  direction: rtl;
  text-align: right;
  margin-bottom: 10px;
}
#manage-files td img,#manage-files td video
{
  object-fit: cover;
}

#manager .intro-item
{
  height: 270px;
  width: 25%;
}

#manager-panel .manager-button
{
  font-size: 13px !important;
  padding: 5px 15px !important;
  border-radius: 50px !important;
  width: 70px;
  cursor: pointer;
  border:1px #af734f  solid;
  background: transparent;
  color: #af734f ;
}
#manager-panel .p-datatable .p-datatable-tbody > tr > td {
 
  padding: 1rem .5rem;
}



    /*سایز موبایل*/
@media only screen and (max-width: 768px)
{

  #manager .intro-item
  {
    padding: 20px;
    margin: 20px;
    width: 90% !important;
  }
  #manager h3
  {
    font-size: 20px !important;
    
  }
  #manager-panel
  {
    width: 100%;
  }
  .nav-bar-item {
    margin-top: 10px !important;
    margin-right: 3px;
    width: 25%;
  }
  .nav-bar-item #logo-icon
  {
    margin-top: -10px;
  }
  #manager .intro-container {
    margin: 50px 0px 0px 0px;
  }
  .manager-table {
    margin: 0px 20px 100px 20px;
  }
  .p-datatable .p-datatable-tbody > tr {
    margin-bottom: 20px;
    display: block;
  }
  #manage-files .p-datatable-tbody > tr > td {
    display: contents !important;
  }
  #manage-files .p-datatable-tbody > tr  {
    padding: 10px 0px 10px 0px;
  }

  #mobile-manager-nav .p-button {
    padding-left: 2px !important;
    font-size: 12px !important;
    border-radius: 0px !important;
  }

#mobile-manager-nav .nav-bar-item i {
  font-size: 20px !important;
}
#mobile-manager-nav .nav-bar-item {
  margin-bottom: 0px !important;
}

#edit-user-dialog
{
  width: 90vw !important;
}
}
  