.p-fluid
{
    direction: rtl !important;
}
.field-checkbox
{
    direction: rtl !important;
}
.field
{
    direction: rtl !important;
}

.p-calendar-w-btn-right .p-inputtext
{
    border-radius: 0px !important;
}

.p-calendar-w-btn-right .p-datepicker-trigger
{
    border-radius: 0px !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
}

.form-demo .card {
    min-width: 450px;
}
.form-demo .card form {
    margin-top: 2rem;
}
.form-demo .card .field {
    margin-bottom: 1.5rem;
}
.register-form h2
{
    margin-bottom: 0px !important;
    
}
.register-form p
{
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    

}
.r-button
{
    width: 70% !important;
}
.r-button.outline
{
    background: transparent !important;
    border: 1px solid #af734f !important;
    color: #af734f !important;
}
.p-toast .p-toast-message {
    margin: 0 !important;
}
.reg-image
{
    width: 170px;
    height: 170px;
}
.two-columns
{
    display: flex;
    position: relative;
}
.col-one,.col-two
{
    position: relative;
    width: 45%;
}
.col-two
{
    margin-right: 20px;
    width: 50%;
}
input
{
    font-size: 14px !important;
}
input::placeholder
{
    font-size: 14px !important;
}
textarea::placeholder
{
    font-family: "dana" !important;
    font-size: 14px !important;
}

.p-fluid .p-dropdown .p-dropdown-label {

    width: 1%;

}
.p-dropdown .p-dropdown-label.p-placeholder {

    color: #6c757d;

}
.p-fluid .p-inputtext {

    width: 100%;

}
.p-dropdown .p-dropdown-label {

    background: transparent;
    border: 0 none;

}
.p-inputtext {

    margin: 0;

}
.p-dropdown-label {

    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    width: 1%;
    text-overflow: ellipsis;
    cursor: pointer;

}
.p-component, .p-component * {

    box-sizing: border-box;

}
.p-inputtext
{
    font-size: 16px !important;
}
.p-password {
    display: block;
}
.outline-button
{
    background: transparent !important;
    border-color: #af734f !important;
    color:  #af734f !important;
}
@media screen and (max-width: 960px) {
    .form-demo .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 0;
    }
}
