.notif-row
{
    display: flex;
    direction: rtl;
    align-items: center;
    margin-bottom: 10px;
}

#notif-tab_content_1 .notif-row
{
    margin-bottom: 20px;
}
.notif-col1
{
    display: flex;
    cursor: pointer;
    width: 15%;
    margin-left: 5px;
}
.notif-col2
{
    display: block;
    font-size: 11px !important;
    width: 55%;
}

.notif-col3
{
    display: table;
    margin: auto;
    width: 30%;
    font-size: 11px;
    border-radius: 30px !important;
    background-color: transparent;
    color: #af734f  !important;
    padding: 5px 0px 5px 0px;
    border: 1px solid #af734f;
}
.notif-col3 p
{
    color: #af734f !important;
    padding: 5px 10px 5px 10px !important;
    margin: 0px !important;
    cursor: pointer !important;
}
.notif-col3 p:hover
{
   
    color: #fff !important;
  
}
.notif-col3 p:focus
{
   
    color: #fff !important;
  
}
.notif-col3 a:hover
{
  color: #ffffff !important;
}
.notif-col3:hover
{
    background-color: #af734f;
    color: #fff !important;
}
.notif-col1 img
{
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    margin-top: 0px !important;
}
.notif-col3 img , .notif-col3 video
{
    width: 40px !important;
    height: 40px !important;
    margin-top: 0px !important;
    cursor: pointer;
    object-fit: cover;
}
.notif-col3 button
{
    font-size: 10px;
    background: #af734f;
    color: #fff;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    padding: 5px 3px 5px 3px;
}

