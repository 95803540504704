#pages-section .intro-item
{
    text-align: center;
    cursor: pointer;
    border: 1px solid #00000015;
    margin: 10px;
    border-radius: 30px;
    height: 400px;
    width: 25%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

#pages-section
{
    margin-top: 0px;
    padding: 80px 50px 70px 50px;
    background-color: #25252514;
    direction: rtl;
}
#apps-section
{
    padding: 50px 15% 70px 15%;
    background-color: #25252514;
    direction: rtl;
    

}

.app-col1, .app-col2
{
    direction: rtl;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin: 3% 30% 0px 30%;
    
}

.app-col1 div
{
    padding: 20%;
    background-color: #ffffff;
    box-shadow: 0 1px 4px rgba(0,0,0,.15);
    border-radius: 30px;
    width: 100%;
    margin-left: 25px;
    height: 500px;

}

.app-col2 div
{
    padding: 15%;
    background-color: #ffffff;
    box-shadow: 0 1px 4px rgba(0,0,0,.15);
    border-radius: 30px;
    width: 100%;
    margin-right: 50px;
    height: 500px;
}
.app-col1 img, .app-col2 img
{
    width: 100%;
    height: 100%;
}


#pages-section p.subtitle {
    color: #404040b8;
    height: 80px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
text-overflow: ellipsis;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
line-height: 20px;
    
  }

#pages-section h2
{
    margin-bottom: 50px;
}

#pages-section .intro-item img {
    border: 1px solid #00000030;
    border-radius: 50%;
}

#pages-section .intro-item:hover
{
    
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.35);
}
#pages-section .intro-item
{
    background-color: #ffffff !important;
    
}


#posts-section .post-item
{
    
    cursor: pointer !important;
    position: relative;
    display: inline-block;
    width: 28%;
    margin: 20px;
    margin-bottom: 50px !important;
    height: 600px;
}
#posts-section #post-content p {
 
    white-space: break-spaces;
    overflow: hidden;
    height: 40px;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 20px;
}

#posts-section #post-col2
{
    margin-right: 10px !important;
}
#posts-section .post-item:hover
{
    background-color: #00000001 !important;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.35);
}
#posts-section #post-pic img, #posts-section #post-video video
{
    object-fit: cover;
    height: 300px;
    width:  100%;
    margin-top: 10px;
}
#posts-section #post-video video
{
    height: inherit;
}
#posts-section img.product-tag
{
    object-fit: initial !important;
    height: fit-content !important;
    width:  20% !important;
    
}

#posts-section
{
    
    padding: 50px 50px 70px 50px;
    background-color: #ffffff;;
    
}


#load-more-btn{
  margin-top: 100px;
  font-size: 18px;
  vertical-align: middle;
  cursor: pointer;
  box-shadow: 0 0 30px 0 #af734f82;
  background-color: #af734f;
  color: #fff;
  border-color: #c9a65400;
  border-radius: 30px !important;
  padding: 10px 50px 10px 50px;
  font-weight: bold;
  direction: rtl;
}

#posts-section #post-content
{
   height:120px;
   
}
#posts-section #post-content div
{
   height:120px;
   overflow: hidden;
}
#posts-section h2
{
    margin-bottom: 50px;
}

#posts-section .intro-item p
{
    text-align: right;
    margin: 10px !important;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    height: 90px;
    
}
.users-banner .post-item .info
{
    text-align: center;
    margin-bottom: 0px;
    margin-top: 30px;
}
#pages-section .intro-item .title {
    font-size: 14px;
    margin-top: 10px !important;
  }

  .install-app
  {
    direction: rtl;
    width: 35vw !important;
    
  }

 
  .install-app img
  {
    height: 190px;

  }
  img.notif-prompt
  {
    width: 100%;
    height: fit-content;
  }
  .install-app p
  {
    font-size: 16px !important;
    
    
  }
  .install-app button
  {
    margin-right: 20px;
    padding: 10px 30px 10px 30px;
  }
  button#cancel-install 
  {
    background-color: transparent !important;
    color: #af734f ;
  }

  .ios-guide .install-logo
  {
    width: 50px;
    height: 50px;
  }

  .ios-guide p
  {
    display:flex;
    width:90%;
    direction:rtl;
    font-size:12px !important;
    text-align:right;
  }
  #posts-section table
  {
    width: 100%;
  }
  #all-products-box
  {
    display:flex;
    direction:rtl;
    align-content:center;
    margin: 50px;
    cursor: pointer;
  }
  #all-products-box div
  {
    display:flex;
    direction:rtl;
    width: 50%;
    box-shadow: 0 1px 4px rgba(0,0,0,.15);
    border-radius: 30px;
    position: relative;
    
    
  }
  #all-products-box img
  {
    width:100%;
    position:relative;
    border-radius: 30px;
    filter: brightness(50%);
  }
  #all-products-box h2
  {
    width:100%;
    height: 100%;
    position:absolute;
    top:40%;
    color: #ffffff !important;
    
  }

  #weblog-section
  {
    padding: 100px 50px;
  }

 .footer-and-copyright
 {
  position: absolute;
  width: 100%;
 }



 /*سایز موبایل*/
 @media only screen and (max-width: 768px) {

    #all-products-box {

        display: block;
        margin: 10px;
    }
    #all-products-box div
    {
        width: 100%;
        margin-bottom: 30px;
        margin-right:0px !important;
        margin-left: 0px !important;
    }

    #pages-section h2, #posts-section h2 {
        font-size: 25px !important;
      }
      .install-app .p-dialog-content
      {
        
        padding: 70px 0px 70px 0px !important;
      }
      .install-app p
      {
        font-size: 16px !important;
        margin-bottom: 30px !important;
        
      }
      .install-app
      {
        width: 80vw !important;
      }

      #pages-section .intro-item,#posts-section .intro-item {
        padding: 20px;
        margin-bottom: 20px !important;
    }
    #pages-section , #posts-section{
        padding: 50px 30px 100px 50px;
    }
    #pages-section .intro-item .title {
        font-size: 16px;
        
      }

    #posts-section .intro-item {
        height: fit-content !important;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    }
    #posts-section .intro-item img
{

    width: 100%;
    height: 100%;
   
    
}
.login-pic img {
    width: 100% !important;
    height: 100% !important;
}
.top-box .login-pic {
    padding-right: 0px !important;
}

#apps-section
{
    padding: 50px 10% 70px 10%;

}
.app-col1, .app-col2
{
    width: 100%;   
    margin: 5% 1% 0% 1%;
    

    
}
.app-col1 div
{
    margin-left: 0px;
    margin-bottom: 50px;
    height: fit-content;

}

.app-col2 div
{
    margin-right: 0px;
    margin-bottom: 50px;
    height: fit-content;
}

#pages-section .intro-item
{
    height: 340px;
    
}
#pages-section p.subtitle {

    height: 80px;
    
    
  }
  #posts-section
  {
    padding: 50px 30px 100px 30px;
    height: fit-content;
  }
  #posts-section .post-item:hover
  {
    box-shadow: none;
  }
  #posts-section .post-item
{

    height: 100%;
    width: 100%;
    direction: ltr;
    display: table;
    margin: auto;

}
#posts-section .post-item .cat-col2
{
    width: 30%;
}


#posts-section .post-item, #posts-section .post-item #post-cat {

  
  margin: 0px !important;
}


 }
