#post
{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}
#post-header
{
    display: flex;
    position: relative;
    width: 100%;
    height: 10%;
    

}
#post-content
{
    position: relative;
    width: 100%;
    height: 30%;
    margin-bottom: 0px;
    margin-top: 20px;
}
#post-content .ltr-line
{
  text-align: left !important;
  margin-top: 5px !important;
  direction: ltr !important;
}
#post-content .rtl-line
{
  text-align: right !important;
  margin-top: 5px !important;
  direction: rtl !important;
 
}
#post-content a
{
  text-align: left;
  display: block;
  cursor: pointer !important;
  font-weight: bold !important;
  color: #af734f !important;
  margin-top: 5px;
}

#post-cat
{
    position: relative;
    width: 100%;
    height: 10%;
    display: flex;
    direction: rtl;
    margin-top: 20px;
    margin-bottom: 5px;
    word-wrap: break-word;

}

#post-pic
{
    position: relative;
    width: 100%;
    height: 40%;

}
#post-reaction
{
    position: relative;
    width: 100%;
    height: 10%;

}

.p-card.p-component.post
{
    width: 100% !important;
}

#post-col1
{
    position: relative;
    width: 30%;
    align-items: center;
    display: flex;
    direction: ltr;
    cursor: pointer;
}
#post-col2
{
    position: relative;
    align-items: center;
    display: flex;
    direction: rtl;
    width: 60%;
    text-align: right;
    margin-right: 5px;
    color: #25252580;
    font-size: 13px;
    cursor: pointer;
}
#post-col3
{
    position: relative;
    width: 10%;
    align-items: center;
    display: flex;
    direction: rtl;
    cursor: pointer;
    
}
#post-col3 img
{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
#post-col1 button
{
    background: transparent !important;
    border: none !important;
    color: #25252580 !important;
    padding: 0px !important;
    float: left;
    margin-right: 10px;
    padding: 1px !important;
    border-radius: 0px !important;
    
}
#post-col1 button:focus
{
    box-shadow: none !important;
    
}
#post-col1 .pi
{
    font-size: 16px;
    
}
#post-header .name 
{
    color: #252525c9;
    margin: 0px !important;
}
#post-header .job
{
color: #25252580;
margin: 0px !important;
font-size: 10px;
}
#post .p-card-body {

    padding-top: 0px !important;
}
#post-content p
{
    font-size: 16px;
    text-align: justify;
    direction: rtl;
    margin-top: 10px;
    white-space: break-spaces;
    line-height: 30px;
    color: #252525c9;
}
 #post-content div , #post-content h1 , #post-content h2
{
  margin-bottom: 0px;
  color: #252525c9;
  text-align: right !important;
  word-wrap: break-word;
  direction: rtl;
}
.cat-col1
{
    float: right;
    direction: rtl;
    font-size: 16px;
    color: #af734f;
    
    width: 78%;
    text-align: right;
}

.cat-col2
{
    float: left;
    direction: rtl;
    color:#25252580;
    font-size: 13px;
    width: 20%;
    text-align: left;
}
.cat-col1 .pi
{
    margin-left: 5px;
    font-size: 12px;
}
#post-pic img
{
    margin-top: 0px;
    width: 100%;
    height: auto;
    border: 1px solid #eee;
    border-radius: 5px;
    height: fit-content;
    object-fit: cover;
}

#post-reaction
{
    margin-top: 5px;
    display: flex;
    direction: rtl;
}
.react-col1
{

    width: 90%;
    float: right;
    direction: rtl;
}

.react-col2
{
    width: 10%;
    float: left;

}
#post-reaction button
{
    background: transparent !important;
    border: none !important;
    color: #25252580 !important;
    padding: 0px !important;
    border-radius: 0px !important;
    direction: rtl;
}
#post-reaction button:focus
{
    box-shadow: none !important;
}
#post-reaction span
{
   
   color: #25252580;
   font-size: 12px;
}
.react-col1 button
{
    float: right;
    margin-left: 30px;

}
.react-col2 button
{
    float: left;
    
    
}
.react-col1 span
{
    margin-right: 8px;
    
}

.react-col1 .pi
{
    /* margin-left: 30px; */
    float: right;
    cursor: pointer;
    
}
.react-col2 .pi
{
    float: left;
    cursor: pointer;
}
.react-col1 .pi:hover , .react-col2 .pi:hover
{
    color: #af734f;    
}

.p-splitbutton-menubutton .pi-chevron-down::before
{
    content: "\e951" !important;

}
.p-menuitem
{
    direction: rtl !important;
    font-size: 12px;
}
.p-menuitem .pi
{
    margin-left: 10px !important;
}
.p-toast-message-text
{
    direction: rtl !important;
}
#comments
{
    height: fit-content;
    direction: rtl;
    display: block;
    margin-top: 20px;
    
}
#comments .row1
{
  
    width: fit-content;
    float: right;
    display: flex;
    align-items: center;
}
#comments .row2
{
 
    width: 90% !important;
    display: flex;
    align-items: center;
    margin-top: 0px !important;
    height: 40px !important;

    
}
#comments .row2 input::placeholder
{
    color: #25252580 !important;

    
}
#comments .row1 img
{
  
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
#prev-comments,#new-comment
{
    display: flex;
    width: 100%;
}
#prev-comments
{
    margin-bottom: 25px;
}
#prev-comments .row2
{
    padding:5px 10px 5px 10px !important;
    height: fit-content !important;
    border-radius: 10px !important;
    border-top-right-radius: 0px !important;
    display: block !important;
    text-align: right !important;
    color: #252525c9 !important;
    direction: rtl !important;
}

#comment-date
{
  color: #25252580 !important;
}
#prev-comments .row2 i
{
    margin-left: 5px;
    font-size: 13px;
}
#prev-comments .row2 p,#prev-comments .row2 h6
{
    text-align: right !important;
}
#prev-comments .row2 h6
{
    margin: 0px !important;
}
#prev-comments .row2 p
{
    margin-top: 15px !important;
    font-size: 15px !important;
}
#prev-comments .row1
{
    align-items: unset !important;
}
#comment-date
{
    float: right;
    font-size: 12px;
}
button.heartLottie 
{
   margin-top: -16px !important;
}
#like-count
{
    margin-right: -9px;
}

.animated-icon
{
    margin-top: -5px !important;
}

.p-component, .p-component * {

    box-sizing: border-box;

}

#post-reaction .heart .Mui-checked span{

    color: #f50057 !important;
  
  }
  .heart span.PrivateSwitchBase-root-1 {
    padding: 0px 0px 0px 8px !important;
    margin-top: -3px !important;
    margin-right: -26px !important;
  }
  .bookmark span.PrivateSwitchBase-root-1 {
  
    margin-top: -11px !important;
    
  }
  .p-confirm-dialog .p-dialog-content {
    direction: rtl !important;
    padding: 0 2em 2rem 3rem !important;
}
.p-dialog-footer .accept
{
    border-radius: 6px !important;
    padding: 10px 30px 10px 30px !important;
    margin-left: 6px !important;
}
.p-dialog-footer .reject
{
    border-radius: 6px !important;
    background: transparent !important;
    color: #af734f !important;
    padding: 10px 30px 10px 30px !important;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 1.2rem !important;
    color: #495057 ;
    margin-left: 7px !important;
  }
  

  #post-reaction button.share
  {
    margin-left: 10px !important;
  }

  #post-header .name,#post-header .job {
    margin-right: 5px !important;
  }

  #post-reaction .pi
  {
    font-size: 20px;
    margin-top: 1px;
  }
  #post .p-fieldset .p-fieldset-content {
    padding: 0px !important;
  }
  #post .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    display: none;
  }
  #post .p-fieldset {
    border: none !important;
  }

  #new-comment span.row2
  {
    border: 1px solid #0000001f !important;
    border-radius: 30px !important;
    margin-right: 10px !important;
    
  }
   #new-comment span.row2 form
  {
    
    width: 93%;
  }
  #new-comment span.row2 .pi
  {
    
    color: #00000052;
    color: #af734f;
    transform: rotate(270deg) !important;
  }
  .p-card {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.32) !important;
  }
  #reply-comments
  {
    margin-right: 8%;
  }
  #prev-comments p , #reply-comments p{
    margin-bottom: 10px;
  }
  #post-reply-banner
{
  background: #0000000a;
  border-radius: 20px;
  padding: 20px 10px;
  margin-top: 20px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;

}
.post-chat-arrow {
  width: 0;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f1f0f0;
  align-self: flex-start;
  color:#0000000a ;
  margin-right: 10%;
  
}
#repost-header
{

  text-align: right;
  display: flex;
    position: relative;
    width: 100%;
    height: 10%;
    margin-bottom: 10px;
}
#repost-header #post-col2
{
  width: 90%;
  margin-right: 17px;
}
#repost-header #post-col3 img
{
  width: 30px;
  height: 30px;
  margin-right: 8px;
  
}
.video-react .video-react-big-play-button {
  top: 38% !important;
  left: 38% !important;

}

.likesDialog .col-two
{
  width: 90% !important;
}

.likesDialog .col-one
{
  width: 0% !important;
}
.tagUser-icon
{
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #ffffff;
  background: #272727;
  border-radius: 50%;
  padding: 6px 10px 8px 10px;
  box-shadow: rgb(255 255 255 / 52%) 1px 1px 10px 1px;
  z-index: 1000;
  height: fit-content;
  cursor: pointer;
}
.taggedUser
{
  position: absolute;
  z-index: 1000;
  height: fit-content;
}
.taggedUser img
{
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
}

.taggedUser span
{
  display: flex;
  direction: rtl;
  align-items: center;
  color: #f1f0f0 !important;
  font-family: "dana-bold" !important;
  font-size: 13px !important;
}
.tagged-user-layout
{
  background: #2727279e;
  padding: 5px 10px;
  border-radius: 30px;
}
#post-video,#post-pic
{
  position: relative;
}
.fade-in {
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s ease-in-out forwards; /* Apply the animation with 1s duration */
  animation-delay: 1s; /* Delay the animation start by 1s */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


#article .p-fieldset-legend
{
  display: none;
}

#article .p-fieldset
{
  border:none;
}


#article #post-col2 h3
{
  font-size: 18px !important;

}


#article #post-content h2
{
  margin-bottom: 20px;
  margin-top: 50px;
  font-size: 25px !important;
}
#article h1#product-header
{
  font-size: 35px !important;
}
#article #post-content h3
{
  margin-bottom: 0px;
}


#article #post-pic img
{
  object-fit: cover;
  cursor: pointer;
}

#article
{
  width: 100%;
  margin-bottom: 30px;
}

#article .react-col1 
{
  width: 80% !important;
}

#article .react-col2
{
  width: 20% !important;
}
#article #new-comment .row1 img 
{
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.21) !important;

}
#article #comments .row1 img
{
  width: 30px;
  height: 30px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.21) !important;

}

#article #new-comment 
{
  display: flex;
  align-items: center;
}
#article #comments-row
{
  margin-top: 50px;
}

#article #comments-section
{
  margin-top: 100px;
}

#article #prev-comments .row2
{
  border: solid #00000024  1px !important;
  margin-right: 10px !important;
  margin-top: 5px !important;
}
#article #prev-comments .row2 {
  
  color: #252525b5 !important;
}

.single_article .p-card
{
  /* border-radius: 10px; */
  border: none;
  box-shadow: none !important;
  background: transparent;
}
#article #post-content img
{
  border-radius: 10px;
}
#article #post-content a
{
  display: inline !important;
}

.single_article  .p-card .p-card-body {
  padding: 0rem;
}

#article #prev-comments .row2 {
  display: flex !important;
  flex-direction: row-reverse;
}
#post-content .view-more-button
{
  text-align: right !important;
}


#article  #post-header
{
  margin-top: -4%;
}

#article .visits_count
{
  font-size: 12px;
}

#article .p-card .p-card-body {
  padding: 2rem;
}

.product_page #post-content img
{
  height: 300px;
  object-fit: contain;
  width: fit-content;
  text-align: center;
  display: table;
  margin: auto;
}

#weblog-section .p-card:hover
  {
  
    transition: transform .7s ease;
    transform: translateY(-10px);
  }

  #article #post-col2 h2
  {
    font-size: 16px !important;
  }


/*سایز موبایل*/
@media only screen and (max-width: 768px) {

  #repost-header
  {
    padding: 0rem 1.25rem 0rem 1.25rem;
  }

    .p-card {
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.32) !important;
        margin-bottom: 20px;
      }

  .create-post-tooltip
  {
    display: none !important;
  }
  #post-header .name
  {
    font-size: 16px !important;
    margin-right: 15px !important;
  }
  #post-header .job {
    text-align: right !important;
    margin-right: 15px !important;
  }

  #post .p-card .p-card-body {
    padding: 0px;
  }
  #post-header,#post-cat,#post-content,#post-reaction
  {
    padding: 0rem 1.25rem 0rem 1.25rem;
  }
  #post .p-card
  {
    border-radius: 0px;
  }
  #post-pic img
  {
    border-radius: 0px;
  }
  #shop-section #post-pic img
  {
    border-radius: 20px;
    box-shadow: 0 1px 4px #25252540;
  }
  img.product-tag
  {
    box-shadow: none !important;
  }

  #shop-section #post-pic
  {
    padding: 10px;
  }
 
  #post {
    margin-bottom: 0px;
  }
  #post-col3 img {
    width: 45px;
    height: 45px;
  }
  #new-comment {
    padding: 0px 10px 0px 10px;
  }
  .cat-col2,.cat-col1 {
    font-size: 15px;
  }
  .cat-col2 {
    font-size: 12px;
  }
  .p-menu .p-menuitem-link .p-menuitem-text {
    font-size: 14px;
  }
  #post-header .job
  {
    font-size: 13px !important;
  }
  #article .react-col1 button {
    
    margin-left: 10px;
  }
  #article #post-reaction .pi {
    font-size: 16px;
    
  }
  #article .animated-icon {
    
    width: 25px !important;
    height: 25px !important;
  }
  #article .p-card .p-card-body {
    
      padding: 30px 10px;
  }
  #article .p-card{
    
    height: fit-content !important;
}
  #weblog-section {
    padding: 100px 30px;
  }
  
  #article #post-reaction {
    padding: 0;
  }
 
}