.footer
{
    height: fit-content !important;
    background-color: #fff;
    background-image: url("../images/2203_w023_n001_1958b_p1_1958.jpg");
    background-size: cover;
    background-position: center center;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;

}
.footer-overlay
{
    background-color: #282828;
    height: fit-content !important;
    opacity: .86;
    transition: background .3s,border-radius .3s,opacity .3s;
    padding-bottom: 30px;
    
}
.copyright
{
    background-color: #000000ed;
    color: white;
    height: fit-content !important;
    padding: 5px 10px 5px 10px;
    
}

.copyright p
{
    color: #ffffff;
    font-size: 14px !important;
}

#footer-logo
{
    margin-top: 30px;
    width: 100px;
    height: 100px;
    filter: brightness(70%);
}
.footer-column
{
    display: table;
    margin: auto;
    width:  30%;
    color: #ffffffc4 ;
    text-align: center;
    align-items: center;
}
.footer-column p
{
    color: #ffffffa6;
}
.footer-column h4
{
    color: #ffffffdb  !important;
}
.footer-link
{
    cursor: pointer;
}
.footer-link:hover
{
    color: #ffffff;
}

#footer-links
{
    display:flex;
    direction:rtl;
    padding:20px 150px 0px 150px;
}

 /*سایز موبایل*/
 @media only screen and (max-width: 768px) {
.copyright {
    height: 50px;
    background-color: black;
    color: white;
    padding-top: 1px;
    padding-bottom: 2px;
    font-size: 12px;
  }

  #footer-links
{
    padding:0px;
}
#footer-links {
    display: block;
    

}
.footer-column
{
    width:  100%;
    margin-top: 60px;
}
 }
