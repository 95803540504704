#chip-input input
{
    width: 100%;
    direction: rtl;
}
.chip-suggestions .user-item
{
  padding: 10px 0px;
}
.chip-suggestions .user-item:hover
{
  background: #f5f5f5;
}
.chip-suggestions .user-item img
{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chip-suggestions .user-item .user-name
{
  color: #495057;
  margin-right: 20px;
}
.chip-suggestions
{
    /* position: fixed; */
    z-index: 10000;
    right: 0;
    width: 100%;
    box-shadow: 5px 5px 4px rgba(0,0,0,.15);
    background: #ffffff;
    border:1px solid rgba(0,0,0,.15);
    padding: 10px;
    /* margin: 0px 50px; */
    animation: slideDownAnimation .5s ease-in-out forwards;
    max-height: 300px;
    height: fit-content;
    overflow: auto;

}
.slide-down {
  position: relative; /* Ensure the element stays within the layout */
  animation: slideDownAnimation 1s ease-in-out forwards; /* Use desired duration and easing */
}
.products-item img
{
  border-radius: 0px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-left: 10px;

}
.products-item
{
  padding: 10px;
}

.user-item , .products-item
{
  cursor: pointer;
}

@keyframes slideDownAnimation {
  0% {
    transform: translateY(-20%); /* Start position off the top */
  }
  100% {
    transform: translateY(0); /* End position at its original location */
  }
}
#chip #chip-selections .selected-item
{
    width: fit-content !important;
    margin-left: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
    background: #af734f61;
    color: #272727b0;
    padding: 3px 10px;
    border-radius:20px;
    
}
#chip #chip-selections .selected-item .pi
{
  margin-left: 5px;
}
.dark #chip #chip-selections .selected-item span
{
  color: #ffffff !important;
}
#chip #chip-selections .selected-item img
{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  object-fit: cover;

}

#chip #chip-input input
{
  margin-top: 5px;
}
