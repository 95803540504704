.top-box {
    height: 520px;
    background: transparent;
    position: relative;
    display: flex;
    padding: 0px 100px 0px 100px;
    text-align: right;

  }

 
.login-pic img 
{
    width: 600px;
    height:330px;
    vertical-align: middle;
    object-fit: cover;
}

  
  .top-box .info {
    position: relative;
    display: inline-block;
    width: 40%;
    margin-left: 50px;
  }
  
  .top-box .info h1 {
    font-size: 40px !important;
    margin-bottom: 30px;
    color: #404040db  !important;
    margin-top: 30px !important;
  }
  
  .top-box .info p {
    margin-bottom: 50px;
    font-size: 20px !important;
    line-height: 35px;
    text-align: justify;
    direction: rtl;
  }
  
  .top-box .login-pic  {
    position: relative;
    display: inline-block;
    width: 60%;
    padding-left: 0px;
    padding-right: 50px;
    height: 300px;
    margin-top: 100px;
  }
  
  .top-box  button {
    font-size: 18px;
    vertical-align: middle;
    cursor: pointer;
    box-shadow: 0 0 30px 0 #af734f82;
background-color: #af734f;
color: #fff;
border-color: #c9a65400;
border-radius: 30px !important;
padding: 10px 50px 10px 50px;
font-weight: bold;


  }
  
  .intro-title2
{
    margin-top: 100px;
}

.search-tags
{
    
    padding: 100px 200px 50px 200px;
    background-color: #25252514;
    
    
}
.tag
{
  margin-bottom: 20px;
}
.search-tags h2
{
    margin-bottom:50px;
    direction: rtl;
}
.tags
{
    display: table;
margin: auto;
}
  
.tags .section1,.tags .section2, .tags .section3
{
    list-style-type: none;
    padding-bottom: 20px !important;
    display: table;
    margin: auto;
}

  .tags .section1 li , .tags .section2 li ,.tags .section3 li
  {
        float: right;

        

  }
  
  
  /*سایز موبایل*/
  @media only screen and (max-width: 768px) {
    .top-box  {
      display: table !important;
      padding: 0px 0px 0px 0px !important;
    }
    .login-pic , .info
    {
      width: 100% !important;
      margin: 0px !important;
      display: table !important;
    }
    .top-box {
      display: contents !important;
    }
    .top-box .info {
      padding: 0px 20px 40px 20px;
    }
    .top-box {
      text-align: center;
    }
    .top-box .info p {
      font-size: 18px !important;
      margin-bottom: 30px !important;
    }
    .intro-title h2 {
      margin-bottom: 0px;
      margin-top: 30px;
    }
    .search-tags {
      padding: 10px 0px 10px 0px;
      
    }
    .search-tags h2 {
      font-size: 30px !important;
      margin-top: 30px;
      direction: rtl;
    }
    .tags .section1 li,.tags .section2 li,.tags .section3 li{
      margin-bottom: 20px;
    }
    .tags .section1,.tags .section2,.tags .section3{
      padding: 0px 20px !important;
    }
    .tag {
      margin-bottom: 0px;
    }
    .intro-title2 {
      margin-top: 50px;
    }
    .search-tags {
      padding: 30px 20px 50px 20px;
    }
  
    
  }
  