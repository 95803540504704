.users-banner {
    height: 500px;
    background: transparent;
    position: relative;
    display: flex;
    padding: 50px 100px 0px 100px;
    text-align: right;
  }
  
  
  
  .posts {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;
    height: 100%;
  }


  .posts .image
  {
    position: relative;
    width: 50%;
    text-align: left;
    padding-left: 70px;

  }

  .posts .image img
  {

    width: 300px;
    height: 300px;
    border-radius: 50%;

  }

  .posts .info
  {
    position: relative;
    width: 50%;
    vertical-align: middle;
  }
  
 
  
  .post-item {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    cursor: default !important;
  }

  .info p
  {
    direction: rtl;
    text-align: justify;
  }
  
  .react-multi-carousel-dot button {
    width: 8px !important;
    height: 8px !important;
    border-color: #af734f;
    border-width: 0px !important;
    background-color: #af734f;
    vertical-align: middle;
    margin: 0px 8px 0px 8px !important;
  }
  
  .react-multi-carousel-dot--active button {
    background: #ffffff !important;
    border-color: #af734f !important;
    width: 12px !important;
    height: 12px !important;
    border-width: 2px !important;
  }
  
 
  
  
  
  @-webkit-keyframes slide {
    100% {
      right: 0;
    }
  }
  
  @keyframes slide {
    100% {
      right: 0;
    }
  }
  
  /*سایز موبایل*/
  @media only screen and (max-width: 768px) {
    .users-banner {
      display: block !important;
      padding: 0px 0px 0px 0px !important;
    }
    .posts , .info
    {
      width: 100% !important;
      margin: 0px !important;
      display: block !important;
    }
    .posts .image img {
      width: 100px;
      height: 100px;
    }
    .post-item
    {
      display: table;
      direction: rtl;
    }
    .posts .image {
      width: 100%;
      text-align: center;
      padding-left: 0px;
    }
  
    
  }
  