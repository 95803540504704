.main-layout
{
    display: flex;
  
}


.main-layout-form-wrap
{
    position: relative;
    width: 40%;
    text-align: right;
    overflow-y: scroll;
    direction: rtl;
    background: white;
    padding-top: 30px;

}

.main-layout-pic-wrap
{
    position: relative;
    width: 60%;
    padding:50px;
    margin-top: 40px;

}
.main-layout-pic-wrap img
{
    width: 60%;
    height: auto;

}

.main-layout-form-wrap h2 ,.main-layout-form-wrap h3 , .main-layout-form-wrap h4 , .main-layout-form-wrap h5
{

    text-align: center;

}

.main-layout .tab-view
{
    padding: 0px 50px 100px 50px;
    background-color: #ffffff;
}
.main-layout-form-wrap .p-tabview
{
    height: 100%;
}
.main-layout-form-wrap .p-tabview-nav-link:active,.main-layout-form-wrap .p-tabview-nav-link:focus
{
    border: none !important;
    box-shadow: none !important;
}
.main-layout-form-wrap .p-checkbox
{
    margin-left: 10px !important;
}

/*سایز موبایل*/
@media only screen and (max-width: 768px) {
.main-layout-pic-wrap {
    display: none;
  }
  .main-layout-form-wrap {
    width: 100%;
    padding-top: 20px;
  }
  .main-layout {
    padding-bottom: 50px;
    background-color: #fff !important;
  }
  .main-layout .tab-view {
    padding: 0px 20px 100px 20px;
  }
  .main-layout .field.col-two
  {
    font-size: 14px;
  }
  
}
