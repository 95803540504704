#dashboard
{
    display: flex;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    background: whitesmoke ;
}

#feed-wrap
{
    position: relative;
    width: 42%;
    background: transparent;
    display: table;
    margin: auto;
}

#sidebar-wrap
{
    position: relative;
    background: transparent;
    width: 30%;
    padding: 0px 20px 0px 20px;
}




#navigation
{
    position: relative;
    background: #ffffff;
    width: 28%;
    margin-top: -20px !important;
    margin-bottom: -20px !important;
    
   
    

}

#no-scrol
{
    position: fixed;
    height: 100vh;
    width: 28%;
}
#notification-section
{
    height: fit-content;
}
#profile-icon img
{
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.MuiLinearProgress-root
{
  transform: rotate(180deg) !important;
}

.view-more-button
{
  background: transparent !important;
  color: #af734f !important;
  font-size: 15px !important;
  margin-bottom: 30px !important;
}
#feed-panel .view-more-button
{
  border: 1px solid #af734f !important;
}

#post-content .view-more-button
{
  font-family: "dana-bold" !important;
  margin-bottom: 0px !important;
}

#searchbox
{
  background: #fff ;
  z-index: 3000;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 100%;
  animation-name: slide-down;
  animation-duration: 0.5s;
  padding: 20px 0px 20px 0px;
  height: 75vh;
  overflow: auto;
}

#searchbox ul
{
  padding-left: 0px;
  margin-top: -20px;
}

#searchbox img
{
  object-fit: cover;
}

@keyframes slide-down
{
  from
  {
    transform: translateY(-10px);
    opacity: 0;
  }
  to
  {
    transform: translateY(0px);
    opacity: 1;
  } 
}

#searchbox .col-one
{
  overflow: hidden !important;
display: -webkit-box !important;
text-overflow: ellipsis !important;
-webkit-line-clamp: 2 !important;
-moz-box-orient: vertical !important;
line-height: 30px !important;
height: 60px;
}
.install-app
  {
    direction: rtl;
    width: 35vw !important;
    
  }


  #theme-setting
  {
    display: table;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #theme-setting .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #8d8d8d36    !important;
    
}

#theme-setting .p-inputswitch .p-inputswitch-slider:before {
  background: #af734f !important;

}

#theme-setting .p-inputswitch .p-inputswitch-slider {
  background: #8d8d8d36    !important;
  box-shadow: 0 0 0 0.2rem #af734f66 !important;
}
#theme-setting .p-inputswitch.p-focus .p-inputswitch-slider
{
  box-shadow: 0 0 0 0.2rem #af734f66 !important;
}




/*سایز موبایل*/
@media only screen and (max-width: 768px)
{
    #sidebar-wrap
    {
        display: none;
    }
    .install-app
      {
        width: 80vw !important;
      }
    #feed-wrap 
    {
        width: 100%;
        position: absolute;
        display: contents;
    }
    #dashboard 
    {
        padding-top: 0px !important;
        display: contents;
    }
    #mobile-nav1
    {
        background: #ffffff;
        padding: 0px 0px 0px 0px;
        margin-bottom: 20px;
        box-shadow: rgba(0, 0, 0, 0.28) 0px 1px 4px
    }
    #profile-icon img {
        width: 50px;
        height: 50px;
        cursor: pointer;
        border-radius: 50%;
        margin-right: 15px;
        margin-top: 9px;
      }
      #logo-icon img {
        width: 60px;
        height: 60px;
        cursor: pointer;
        margin-left: 10px;
        margin-top: 0px;
        
      }
      #notification-section h5
      {
        margin: 10px 10px 20px 20px;
      }
      #mobile-sidebar-dark .profile-panel-header,#mobile-sidebar-light .profile-panel-header
      {
        direction: rtl;
        display: table;
        margin: auto;
        
      }

      #mobile-sidebar-dark .profile-panel-header .col-three,#mobile-sidebar-light .profile-panel-header .col-three
      {
        display: table;
        margin: auto;
      }
      #mobile-sidebar-dark .profile-panel-header .col-two,#mobile-sidebar-light .profile-panel-header .col-two
      {
        display: table;
        margin: auto;
        font-size: 14px;
        width: fit-content;
        width: 100% !important;
        text-align: center;
        margin-top: 5px;
      }
      #mobile-sidebar-dark .profile-panel-header .col-one,#mobile-sidebar-light .profile-panel-header .col-one
      {
        display: table;
        margin: auto;
        font-size: 18px;
        width: 100% !important;
        text-align: center;
      }

      #mobile-sidebar-dark .profile-panel-header .col-three img,#mobile-sidebar-light .profile-panel-header .col-three img
      {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-top: 10px;
      }
      #mobile-sidebar-dark .profile-panel,#mobile-sidebar-light .profile-panel
      {
        direction: rtl;
      }
    
      #mobile-sidebar-dark .profile-panel .col-two,#mobile-sidebar-light .profile-panel .col-two
      {
        font-size: 10px;
        width: fit-content;
      }
      #mobile-sidebar-dark .profile-panel .col-one,#mobile-sidebar-light .profile-panel .col-one
      {
        font-size: 14px;
      }
      #mobile-sidebar-dark .panel-row .col-one,#mobile-sidebar-light .panel-row .col-one 
       {
        width: fit-content !important;
        margin-left: 5px !important;
      }
      #mobile-sidebar-dark .panel-row .col-two,#mobile-sidebar-light .panel-row .col-two  
      {
        font-size: 16px !important;
      }
      #mobile-sidebar-dark .panel-row .col-one img,#mobile-sidebar-light .panel-row .col-one img  
      {
        width: 50px;
        height: 50px;
      }
      #mobile-sidebar-dark .panel-row .pi,#mobile-sidebar-light .panel-row .pi  
      {
        font-size: 23px;
      }
      #feed-wrap {
        padding-bottom: 60px;
      }
      #profile-content{
        padding-bottom: 60px;
        background: whitesmoke;
      }

      #create-group-dialog
      {
        margin: 10px;
      }
      #create-page-dialog
      {
        margin: 10px;
      }
      #user-profile
      {
        width: 100% !important;
      }
      #crop-profile
      {
        margin: 0px 10px 0px 10px !important;
      }
      
}
