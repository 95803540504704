#login-form h2
{
    margin-bottom: 0px !important;
}
#login-form p
{
    margin-top: 0px !important;
    margin-bottom: 50px !important;
}
button.login-dialog 
{
    width: 100% !important;

}

.login-dialog.mobile-login
{
    background: transparent !important;
    color: #af734f !important;
    border: 1px solid #af734f !important;
}