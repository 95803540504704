#feed-panel,
#search-panel,
#notification-panel,
#shop-panel {
  padding: 0px;
}

#feed-panel p,
#search-panel p,
#shop-panel p {
  text-align: right !important;
  font-size: 14px !important;
  font-family: "dana-bold" !important;
  color: #404040db;
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 10px 10px 0px 10px;
}
#notification-panel {
  padding: 20px 0px 20px 0px;
}
#notification-panel h6{
  margin-right: 10px !important;
}
.panel-row {
  display: flex !important;
  vertical-align: middle;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}
.panel-row img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.panel-row .pi {
  vertical-align: middle;
  font-size: 20px;
}

.panel-row .col-one {
  position: relative !important;
  width: 20% !important;

  float: right;
  direction: rtl;
  align-items: center;
}

.panel-row .col-two {
  position: relative;
  width: 80%;
  text-align: right;
  margin-right: 0px;
  display: flex;
  float: right;
  direction: rtl;
  align-items: center;
}

.panel-row2 {
  display: flex !important;
  vertical-align: middle;
  padding: 10px 10px 10px 10px;
  margin-top: 0px;
  cursor: pointer;
  font-size: 14px;
}
.panel-row2:hover {
  background: #eee;
}

.panel-row2 .pi {
  vertical-align: middle;
  font-size: 20px;
  background: #af734f1c;
  padding: 10px;
  border-radius: 5px;
  color: #af734f;
}

.panel-row2 .col-one {
  position: relative !important;
  width: 20% !important;
}

.panel-row2 .col-two {
  position: relative;
  width: 80%;
  text-align: right;
  margin-right: 10px;
  padding: 10px 0px 10px 0px;
}

.panel-row3 {
  display: flex !important;
  vertical-align: middle;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #252525c9;
}
.panel-row3:hover {
  color: #af734f !important;
}

.panel-row3 .pi {
  vertical-align: middle;
  font-size: 20px;
}

.panel-row3 .col-one {
  position: relative !important;
  width: 20% !important;
}

.panel-row3 .col-two {
  position: relative;
  width: 80%;
  text-align: right;
  margin-right: 0px;
}
#notification-panel p {
  text-align: center;
  margin-top: 0px;
}
#notification-panel img {
  width: 200px;
  height: 200px;
  margin-bottom: 0px;
  margin-top: 40%;
}
#create-group-dialog label {
  float: right !important;
  margin-bottom: 10px !important;
  font-size: 14px !important;
}
#create-group-dialog input {
  direction: rtl !important;
  text-align: right !important;
  font-size: 14px !important;
}
#create-group-dialog input::placeholder {
  font-size: 12px !important;
}
#create-group-dialog .p-dropdown {
  text-align: right !important;
  float: right !important;
  direction: rtl !important;
  font-size: 14px !important;
}
#create-group-dialog strong {
  font-family: "dana-bold" !important;
  font-weight: normal !important;
  color: #af734f !important;
}
#create-group-dialog .p-dialog-header {
  padding-bottom: 10px !important;
  border-bottom: 1px solid #eee !important;
}
#create-group-dialog_content {
  padding-top: 20px !important;
}
#notification-panel .not-seen
{
  background: #af734f29;
}
#notification-panel .not-seen, #notification-panel .seen
{
  padding: 10px;
}
#notification-panel h6
{
  margin-bottom: 20px !important;
}
#notif-tab li {
  font-size: 13px !important;
}



/*سایز موبایل*/
@media only screen and (max-width: 768px) {
#notification-panel
{
  height: 100vh;
  background: #fff;
}
#notification-panel span,#notification-panel div,#notification-panel p,#notification-panel a
{
  font-size: 14px !important;
}

.notif-row
{
  padding: 5px;
}
#notification-panel .p-tabview-panels
{
  padding-top: 20px !important;
}
#notif-tab_content_1
{
  padding: 0px 15px 0px 15px;
}
#notification-panel .p-tabview-title
{
  font-size: 16px !important;
}
#notif-tab_content_1, #notif-tab_content_0 {
  padding: 0px;
}
#notif-tab_content_0,#notif-tab_content_1
{
  margin-bottom: 150px;
}
}