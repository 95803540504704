#settings
{
    width: 72%;
    position: relative;
    margin-top: -20px;
    
}
#settings h6
{
    margin-top: 30px !important;
}
#account-tab ul{
    display: block ruby;
    width: 100%;
}
#settings .p-tabview .p-tabview-panels
{
    background: transparent !important;
}
 
#settings .p-tabview-title
{
    font-size: 14px !important;
}
#settings .p-tabview-nav
{
    padding-top: 20px !important;
}
#settings .settings-box
{
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
    margin: 0px 150px 0px  150px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}
#settings h6
{
    margin-bottom: 10px;
    text-align: right;
    margin-right: 150px;
    
}
.settings-row
{
    display: flex;
    direction: rtl;
    font-size: 14px !important;
    padding: 0px 0px 10px 0px;
    cursor: pointer;
}
.settings-col1
{
    display: flex;
    width: 5%;
    align-items: center;
    color: #25252580;
}
.settings-col1 img
{
    width:40px;
    height:40px;
    border-radius: 50%;
}
.settings-col2
{
    display: flex;
    width: 45%;
    align-items: center;
    margin-right: 10px;
}
.settings-col3
{
    display: flex;
    width: 45%;
    align-items: center;
    color: #2525254d;
    direction: ltr;
padding-left: 20px;
}
.settings-col4
{
    display: flex;
    width: 5%;
    align-items: center;
    color: #25252580;
    cursor: pointer;
}
.settings-box input
{
    border-radius: 5px !important;
border: 1px solid #25252526  !important;
background: transparent  !important;
padding: 5px !important;
}
.settings-box button
{
    color: #ffffff !important;
    background: #af734f !important;
    border-radius: 30px;
    border: 1px solid #af734f !important;
    padding: 5px 20px 5px 20px !important;
}
.settings-box .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #af734f !important;
  }

  .p-inputtext.p-invalid.p-component {
    border-color: #e24c4c !important;
}
.settings-box .p-password input
{
    width: 100% !important;
}

.p-selectbutton .p-button.p-highlight {
    color: #fff !important;
    background: #af734f  !important;
    
  }
  .p-selectbutton .p-button {
    border: 1px solid #af734f !important;
    color: #af734f !important;
    background: transparent !important;
    float: right !important;
    margin: 10px !important;
  }




/*سایز موبایل*/
@media only screen and (max-width: 768px)
{

#settings {
    width: 100%;
    padding-bottom: 50px;
  }

  #settings .p-tabview-title {
    font-size: 12px !important;
  }

  .p-tabview .p-tabview-panels {
    padding: 0.25rem !important;
  }

  .p-tabview-panels p {
    
    margin-right: 0px !important;
    text-align: center !important;
  }

  #settings .settings-box {
    padding: 20px;
    margin: 0px 10px 0px 10px;
  }
  #settings .p-tabview .p-tabview-panels {
    padding-bottom: 70px !important;
  }
  .settings-col2 {
    margin-right: 20px;
  }
.p-tabview-panels   .about-box  p {
    
    text-align: right !important;
}

}