#nav-bar-and-pannel {
  height: 100vh;
  display: flex;
  height: 100vh;
    background: #ffffff;

  
}

#nav-bar-wrap
{
  position: relative;
  width: 20%;
  border-left: 1px solid #eee;
}

#nav-bar-panel
{
  position: relative;
  width: 80%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  overflow: auto;

}
/* customize scrollbar */

/* width */
#nav-bar-panel::-webkit-scrollbar {
  width: 4px;
}

/* Track */
#nav-bar-panel::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
#nav-bar-panel::-webkit-scrollbar-thumb {
  background: rgb(214, 214, 214);
  border-radius: 30%;
}

/* Handle on hover */
#nav-bar-panel::-webkit-scrollbar-thumb:hover {
  background: rgb(187, 187, 187);
}

#nav-bar-wrap #logo-icon img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
#nav-icons {
  height: 70%;
}
#logo-icon {
  height: 15%;
}
#profile-icon {
  height: 15%;
}
#nav-bar-wrap #profile-icon img {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
}
.nav-bar-item i {
  font-size: 33px;
  width: 100%;

  color: #404040db;
  cursor: pointer;
}

.nav-bar-item i:hover {
  color: #af734f;
}
.nav-bar-item {
  margin-bottom: 15px;
  
}
.nav-bar-item:active {
  background: #eee;
}
.nav-bar-item:focus {
    background: #eee;
  }
.p-sidebar-right .p-sidebar {
  width: 20rem;
  height: 100%;
  margin-right: 6%;
}
#nav-bar-wrap button {
  background: transparent !important;
  border: none !important;
  border-radius: 0px !important;
  padding: 15px 15px 15px 15px;
  margin:0px;
  width: 100%;
  
}
#nav-bar-wrap button:focus {
  background: transparent !important;
  background: #eee !important;
  box-shadow: none;
  
}


.p-sidebar-header {
  display: none !important;
}
.nav-bar-tooltip {
  margin-right: 6%;
  width: 200px;
  z-index: 1000;
  font-size: 14px;
  text-align: right;
  direction: rtl;
}

#nav-bar-wrap .p-badge {
  position: absolute;
  padding-top: -10px;
  top: 0;
  right: 0;
  margin-left: 0px !important;
  margin-right: 5px !important;
}
.p-overlaypanel-content .profile-panel
{
    display: flex !important;
    vertical-align: middle;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    font-size: 14px;
}
.p-overlaypanel-content .profile-panel:hover
{
    background: #af734f;
    color: #ffffff;
    
}
.p-overlaypanel-content .profile-panel .col-one
{
    position: relative !important;
    width: 10% !important;
}
.p-overlaypanel-content .profile-panel .col-one a
{
    background: #af734fd1;
    color: #ffffff;
    padding: 5px 3px 5px 3px;
    border-radius: 35px;
    margin-top: 5%;
    
}
.p-overlaypanel-content .profile-panel .col-one a:hover
{
    background: #af734f;
    
}
.p-overlaypanel-content .profile-panel .col-two
{
    position: relative;
    width: 70%;
    text-align: right;
    margin-right: 20px;
}
.p-overlaypanel-content .profile-panel .col-two p
{
    margin: 0px
}
.p-overlaypanel-content .profile-panel .col-two p.name:hover
{
    color: #ffffff;
}
.p-overlaypanel-content .profile-panel .col-two p.name
{
    font-size: 14px;
    color:#252525c9;
    margin-bottom: 0px;
}
.p-overlaypanel-content .profile-panel .col-two p.job
{
    font-size: 12px;
    color: #25252580;
}
.p-overlaypanel-content .profile-panel .col-three
{
    position: relative;
    width: 20%;
    margin-right: 20px;
}
.p-overlaypanel-content .profile-panel .col-three img
{
    width: 40px;
    height: 40px;
    border-radius: 50px;

}

.p-overlaypanel-content .p-divider
{
    margin-bottom: 10px !important;
margin-top: 10px !important;
}




/*سایز موبایل*/
@media only screen and (max-width: 768px)
{
    #nav-bar-panel
    {
        display: none;
    }
    .p-sidebar-right .p-sidebar {
      margin-right: 0%;
    }
    .nav-bar-item {
      margin-bottom: 10px;
    }

    .nav-bar-item .p-badge.p-badge-danger {
      margin-top: -30px;
      margin-left: 0px;
      position: absolute;

    }
    .nav-bar-item button{
      background: transparent !important;
      border: none !important;
    }

    .nav-bar-item .pi-plus
    {
      color: #fff !important;
      background: rgb(175, 115, 79);
      padding: 10px;
      border-radius: 50%;
      display: table;
      font-size: 20px;
      margin: 0px !important;
    }
  

    
   

}