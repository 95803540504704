#suggestion-box-wraper
{
    background: #ffffff !important;
    border-radius: 5px;
    padding: 10px 20px 0px 20px;
    margin-bottom: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    height: fit-content;
    
}

#suggestion-box .title
{
    text-align: right;
    margin-top: 0px;
    margin-bottom: 15px;
}
#suggestion-box .col-one a , #suggestion-box a.button 
{
    width: 100%;
    border-radius: 0px;
    border: 0px;
    padding:10px;
    background: #eee;
    cursor: pointer;
    display: block;
color: #2525258a;
font-size: 12px;
margin-top: 10px;

}
#suggestion-box .col-one a:hover
{
    color: #ffffff !important;

}
#suggestion-box .row
{
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 10px;
}
#suggestion-box .row .col-one
{
    display: flex;
    align-items: center;
    direction: rtl;
    position: relative;
    width: 40%;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -moz-box-orient: vertical;
    line-height: 30px;
}
#suggestion-box .row .col-one a
{
    background: #af734fd1;
    color: #ffffff;
    padding: 5px 3px 5px 3px;
    border-radius: 35px;
    margin-top: 5%;
    font-size: 12px;
    
}
#suggestion-box .row .col-one a:hover
{
    background: #af734f;
    
}
#suggestion-box .row .col-two
{
    display: flex;
    align-items: center;
    direction: rtl;
    position: relative;
    width: 50%;
    text-align: right;
    margin-right: 20px;
}
#suggestion-box .row .col-two p
{
    margin: 0px 10px 0px 10px
}
#suggestion-box .row .col-two p.name
{
    font-size: 12px;
    color:#252525c9;
    margin-bottom: 0px;
}
#suggestion-box .row .col-two p.job
{
    font-size: 12px;
    color: #25252580;
}
#suggestion-box .row .col-three
{
    display: flex;
    align-items: center;
    direction: rtl;
    position: relative;
    width: 10%;
    margin-right: 20px;
}
#suggestion-box .row .col-three img,#suggestion-box .row .col-three video
{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); */

}
#suggestion-box-wraper #suggestion-box
{
    position: relative;
    top: 50%;
    padding: 10px 0px 20px 0px;
    margin-bottom: 20px;
    
}
.followed a
{
    background: transparent !important;
    color: #af734f !important;
    border: 1px solid #af734f !important;
    border-radius: 30px !important;
    padding: 5px !important;
}
.followed a:hover
{
    background: #af734f !important;
    color: #ffffff !important;
    border: 1px solid #af734f !important;
}

#suggestion-box .row .col-two span.name , #suggestion-box .row .col-two p.job {
    margin-right: 15px !important;
    text-align: right !important;
    font-size: 14px !important;
    word-wrap: anywhere;
  }

  #shop-panel #suggestion-box .row .col-three img
  {
    object-fit: cover;
  }

  #search-tab_content_1 #suggestion-box .row .col-one
  {
    text-align: right;
    overflow: hidden;
    vertical-align: top;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 20px;
  
  }
  #search-section #suggestion-box .row .col-two {

    width: 100%;
  }
  #blue-tick
  {
    width: 25px !important;
    height: 25px !important;
    margin-right: 10px;
  }

  /*سایز موبایل*/
@media only screen and (max-width: 768px)
{

  #suggestion-box .row .col-one a {
    font-size: 13px;
  }
  #suggestion-box .row .col-one a
{
    background: #af734f;
}

}
