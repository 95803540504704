#crop-avatar
{
    padding-bottom: 0px;
}
#crop-avatar #show-avatar
{
    padding: 20px 30% 0px 30%;
}
#crop-avatar #edit-avatar
{
    padding: 20px 0% 20px 0%;
}
#crop-avatar input,#crop-cover input
{
    direction: rtl;
    width: inherit;
    text-align: center;
}
.MuiSlider-root {
    color: #af734fff !important;
  }

#edit-avatar canvas
{
    width: 300px !important;
    height: 300px !important;
}
#edit-cover canvas
{
    width: 500px !important;
    height: 200px !important;
}
button#brows-button
{
    background: transparent !important;
    border: none !important;
    margin-bottom: 20px;
}
#edit-avatar button,#edit-cover button
{
    background: transparent;
    border: 1px solid #af734f  !important;
    color: #af734f;
    font-size: 14px !important;
    margin-left: 10px !important;
    border-radius: 30px !important;
    cursor: pointer !important;
    padding: 10px 30px 10px 30px !important;

}

/*سایز موبایل*/
@media only screen and (max-width: 768px)
{

#edit-cover canvas {
    width: 300px !important;
    height: 150px !important;
  }

}

