#new-page p
{
    font-size: 14px !important;
    width: 100%;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    
}
#page-types
{
    display: flex;
    align-items: center;
    direction: rtl;
    margin: 10px 0px 0px 0px;
}
#page-type-col1,#page-type-col2,#page-type-col3
{
    margin-top: 0px;
    position: relative;
    width: 33.33%;
    align-items: center;
    text-align: center;
    font-size: 14px !important;
    cursor: pointer;
    padding: 0px 10px 10px 10px;
    
}
#page-type-col1:hover,#page-type-col2:hover,#page-type-col3:hover
{
    background: #eee;
}
#page-types h6
{
    margin: 0px !important;
    font-size: 14px !important;
}

#page-types img
{
    width: 80px;
    height: 80px;
}
#create-page-dialog_content
{
    padding-top: 10px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
}
#create-page-dialog .p-dialog-header
{
    border-bottom: 1px solid #eee !important;
    
}
