#weblog-container
{
    padding: 50px 50px 100px 100px;
}

#weblog
{
    display: flex;
    padding-bottom: 100px;
}

#weblogsidebar2 ,#weblogsidebar1
{
    display: flex;
    width: 25%;
    margin-right: 50px;
    
}
#weblogsidebar2 .sidebar_container {
  margin-bottom: 50px;
  padding: 40px 40px 100px 40px;
  margin: 20px 20px 0px 0px;
  overflow: auto;
  background: #ffffff;
  border: 1px #49505724 solid;
  border-radius: 10px;
  scrollbar-width: none; /* Firefox */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.09) !important;
}

#weblogsidebar1 .sidebar_container {
  margin-bottom: 50px;
  padding: 40px 40px 100px 40px;
  margin: 60px 0px 0px 20px;
  overflow: auto;
  width: 20%;
  background: #ffffff;
  border: 1px #49505724 solid;
  border-radius: 10px;
  scrollbar-width: none; /* Firefox */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.09) !important;
  
}

#weblogsidebar1 .sidebar_container::-webkit-scrollbar,
#weblogsidebar2 .sidebar_container::-webkit-scrollbar {
display: none; /* Webkit browsers */
}


#weblogMainPanel
{
    display: flex;
    width: 75%;
    padding-right: 0px;
    padding-left: 30px;
}

#weblog-container .mainTitle
{
    font-size: 25px !important;
    margin-bottom: 0px;
    text-align: right !important;
    word-wrap: break-word;
    direction: rtl;
}


#weblog .title
{
    font-size: 16px !important;
    margin-bottom: 20px;
    text-align: right;
    color: #4040409c !important;
}

#weblog-container .subtitle
{
    margin-bottom: 50px;
    direction: rtl;
    text-align: right;
}

.no-scrol {
    height: 100vh;
    position: fixed;
   
  }

  #searchSection
  {
    width: 100%;
    background: #00000008  !important;
    border-radius: 10px !important;
    padding:5px 0px;
  }

  #searchSection .search-box-wraper
  {
    margin-top: 0px !important;
  }

  #otherArticlesSection
  {
    background: #ffffff  !important;
    border: #00000017  solid 1px;
    border-radius: 10px !important;
    width: 100%;
    margin-top: 30px;
    padding: 20px 40px;
   
  }

  #tagsSection
  {
    background: #ffffff  !important;
    border: #00000017  solid 1px;
    border-radius: 10px !important;
    width: 100%;
    margin-top: 30px;
    padding: 30px 20px 30px 20px;
   
  }

  #tagsSection .section1
  {
    margin:0px !important;
    padding: 0px !important;
  }

  #tagsSection .button button
  {
    padding: 0px 10px;
    font-size: 12px;
    background-color: #ae714d29;
  }
  
  #weblog .login-button
  {
    font-size:14px !important; 
  }
  #riderSection
  {
    background: #00000008  !important;
    border-radius: 10px !important;
    width: 100%;
    margin-top: 0px;
    padding: 30px 20px 30px 20px;
    background: #ffffff  !important;
    border: #00000017  solid 1px;
  }


  #horseSection
  {
    background: #ffffff  !important;
    border: #00000017  solid 1px;
    border-radius: 10px !important;
    width: 100%;
    margin-top: 30px;
    padding: 30px 20px 30px 20px;
  }


  .other_articles 
  {
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    list-style-type: none;
    /* float: right; */
  
  
  
  }

  .other_articles li
  {

    border: #00000017 solid 1px;
    padding-bottom: 10px;
    border-radius: 10px;
    background: whitesmoke ;
   
  
  }

  .other_articles img
  {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .other_articles h3
  {
    font-size: 14px !important;
    text-align: right;
    margin-right: 10px;
    margin-left: 10px;
    direction: rtl;
    height: fit-content;
    word-wrap: anywhere;
    margin-top: 10px;

  }
  #riderSection h3 ,  #horseSection h3
  {
    font-size: 11px !important;
    height: 50px !important;
  }
 
  #related-articles .other_articles h3
  {

      height: fit-content;
  }
  #related-articles .other_articles li {

    height: 360px;
  }
  .other_articles p
  {
    font-size: 13px !important;
    text-align: justify;
    margin-right: 10px;
    margin-left: 10px;
    direction: rtl;
    word-wrap: anywhere;

  }

  #weblog-container
  {
    width: 100%;
  }

  #weblogsidebar1 .other_articles img
  {
    height: 100px;
  }

  #comments-section
  {
    text-align:right;
    margin-top:30px;
    direction: rtl;
  }
  #comments-section p 
  {
    font-size: 14px;
  }

  #related-articles
  {
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: right;
  }
  #related-articles img
  {
    height: 200px;
  }

  #weblog-nav 
  {
    margin-bottom: 20px;
  }

  #weblog-nav p
  {
    font-size: 13px;
  }


  #weblog #searchbox
  {
    width: 89% !important;
  }

  #weblog .search-row
  {
    padding:20px !important;
  }

  #weblog .search-row img
  {
    width: 45px !important;
    height: 45px !important;
  }

  #paging-section
  {
    margin-top: 50px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #af734f;
    color: #af734f;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination-button.active {
    
    background-color: #af734f;
    color: white;
  }

  .pagination {
    direction: rtl;
  }

  .other_articles li:hover
  {
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.12) !important;  
  }
  
  .multiple_article .p-card
  {    
    transform: translateY(0);
  }
  .multiple_article .p-card:hover
  {
  
    transition: transform .7s ease;
    transform: translateY(-10px);
  }

  #content-section
  {
    margin: 100px 0px;
    background: #ffffff;
    padding: 50px 30px;
    border-radius: 10px;
    height: 1000px;
    overflow: scroll;
  }

  ._whatsappButton_181xn_23
  {
    left: 2rem;
  }
  ._whatsappChatBox_181xn_95
  {
    left:4rem;
    direction: rtl;
    height: 400px !important;
  }
  ._tooltip_181xn_903
  {
    left:6rem;
    right: auto !important;
  }
  ._tooltipArrow_181xn_935 {
  
    left: -10px !important;
    transform: rotate(180deg);
  }
  ._status_181xn_229
  {
    text-align: right;
  }
  ._statusSubtitle_181xn_249 {
    font-size: .6rem;
  }
  ._triangle_181xn_487
  {
    right: -10px !important;
    left: auto !important;
    transform: rotate(180deg);
  }
  ._chatBody_181xn_325 ._message_181xn_435 {
   
    text-align: right !important;
  }
  ._floatingWhatsapp_181xn_1 {
    position: absolute !important;
    width: auto !important;
    height: auto !important;
  }
  

  
  


  /*سایز موبایل*/
@media only screen and (max-width: 768px) 
{

  #weblogMainPanel {
    
    width: 100%;
    padding-left: 0px;
  }

  #weblog-container {
    padding: 10px 30px;
    padding-bottom: 100px;
  }

  #weblog
  {
    display: block !important;
    padding-bottom: 0px;
  }
  #weblog #article #post-content {
    padding: 0rem;
  }

  #weblogsidebar2 .no-scrol ,  #weblogsidebar1 .no-scrol
  {
    position: relative;
  }
  #weblogsidebar2, #weblogsidebar1
  {
    display: block;
    width: 100%;
  }
  #weblogsidebar2 .sidebar_container , #weblogsidebar1 .sidebar_container {
    
    margin: 0px;
    padding:30px;
    border-radius: 0px;
    padding-bottom: 100px;
    width: 100%;
  }

  #weblogsidebar1 .other_articles img {
    height: 200px;
  }

  .product_page_container #weblog-container
  {
    padding: 30px 0px;;
  }

  .product_page_container .multiple_article,
  .product_page_container #weblog-nav,
  .product_page_container .mainTitle,
  .product_page_container #paging-section
  {
    padding: 0px 30px;
  }

  .product_page_container #content-section
  {
    margin: 0px;
    height: fit-content;
    /* margin-bottom: -130px; */

  }

  #weblog .subtitle 
  {
    margin-bottom: 50px !important;
  }



}